import React, { useState, useEffect } from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';

import { PanelLeft, Crown, Home, Bell, LogOut } from 'lucide-react';

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider
} from '../src/components/ui/tooltip';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator
} from '../src/components/ui/breadcrumb';
import { Sheet, SheetContent, SheetTrigger } from '../src/components/ui/sheet';
import { Button } from '../src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../src/components/ui/dropdown-menu';
import smallLog from '../src/assets/images/favicon-scale-secure-white.png';
import largeLog from '../src/assets/images/Scale-secure-logo-white.png';
import noProfile from '../src/assets/images/no-profile-pic.jpeg';
import { store } from '../src/redux/store';
import { resetRedux, setToggle } from '../src/redux/actions';
import withUser from '../src/redux/Hoc/withUser';
import { Cookies } from 'react-cookie';

import {
  CollapseIcon,
  PrerequisiteIcon,
  LoadtestmetreIcon,
  SecurityIcon,
  ArchitectureIcon,
  BasicUserIcon,
  ProUserIcon,
  EnterpriseUserIcon,
  StandardUserIcon,
  APIAutomation
} from './assets/icons';
import NotificationMenu from './components/Notification';
import { useSelector, useDispatch } from 'react-redux';
// import { getAccessToken } from './utils/util';
import { refreshToken } from './utils/axiosConfig'

function Layout({ user }) {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState('/');
  const paths = location.pathname.split('/').filter(Boolean).slice(0, 2);
  const dispatch = useDispatch();
  const collapsed = useSelector((state) => state.toggle);
  const navigation = [
    {
      name: 'Home',
      href: '/',
      icon: <Home className="h-5 w-5" />,
      current: currentRoute === '/'
    },
    {
      name: 'Prerequisite',
      href: '/prerequisite',
      icon: <PrerequisiteIcon className="h-5 w-5" fill="white" />,
      current: currentRoute.startsWith('/prerequisite')
    },
    {
      name: 'Load',
      href: '/load',
      icon: <LoadtestmetreIcon className="h-5 w-5" />,
      current: currentRoute.startsWith('/load')
    },
    {
      name: 'Security',
      href: '/security',
      icon: <SecurityIcon className="h-5 w-5" />,
      current: currentRoute.startsWith('/security')
    },

    {
      name: 'Architecture',
      href: '/architecture',
      icon: <ArchitectureIcon className="h-5 w-5" />,
      current: currentRoute.startsWith('/architecture')
    },
    {
      name: 'API Automation',
      href: '/APIAutomation',
      icon: <ArchitectureIcon className="h-5 w-5" />,
      current: currentRoute.startsWith('/APIAutomation')
    },
    {
      name: 'Plans',
      href: '/plans',
      icon: <Crown className="h-5 w-5" />,
      current: currentRoute.startsWith('/plans')
    }
    // {
    //   name: 'documentation',
    //   href: '#',
    //   icon: <FileText className="h-5 w-5" />,
    //   current: currentRoute === '#'
    // },
    // {
    //   name: 'Support',
    //   href: '#',
    //   icon: <Headset className="h-5 w-5" />,
    //   current: currentRoute.startsWith('/support')
    // }
  ];
  const handleToggle = () => {
    dispatch(setToggle(!collapsed)); // Dispatch the action to toggle state
  };
  const [refreshIntervalId, setRefreshIntervalId] = useState(null);

  // const [collapsed, setCollapsed] = useState(true);
  // const [userType, setUserType] = useState('basic');
  const navigate = useNavigate();
  const userType = 'Basic';
  const userIcons = {
    Basic: <BasicUserIcon />,
    Pro: <ProUserIcon />,
    Enterprise: <EnterpriseUserIcon />,
    Standard: <StandardUserIcon />
  };

  const destroySession = async () => {
    const cookies = new Cookies();

    cookies.remove('authToken', { path: '/' });
    cookies.remove('session', { path: '/' });
    cookies.remove('user', { path: '/' });

    sessionStorage.clear();
    localStorage.clear();

    console.log('Cookies after destruction:', cookies.get());
    console.log('Session Storage after destruction:', sessionStorage);
    console.log('Local Storage after destruction:', localStorage);
  };

  // Set up the token refresh interval on component mount
  useEffect(() => {
    const intervalId = setInterval(
      async () => {
        try {
          await refreshToken();
        } catch (error) {
          console.error('Error refreshing token', error);
        }
      },
      14 * 60 * 1000
    );

    setRefreshIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    console.log("user", user)
  }, [])
  const onLogout = async () => {
    await store.dispatch(resetRedux());
    await destroySession();
    if (refreshIntervalId) {
      clearInterval(refreshIntervalId);
      setRefreshIntervalId(null);
    }
    window.location = '/login';
  };

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  const handleSubscriptions = () => {
    window.location.href = '/subscriptions';
  };

  const handleAccount = () => {
    window.location.href = '/profile';
  };

  // console.log('After Call', user.access_token);

  const handleButtonClick = async () => {
    navigate('/plans');
  };

  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleNotificationMenuToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setIsNotificationMenuOpen((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsNotificationMenuOpen(false);
  };
  return (
    <div className="flex h-auto bg-[#11141F] manrope-fontCss hide-scrollbar">
      <aside
        className={`fixed inset-y-0 left-0 z-50 flex flex-col transition-all duration-300 border-2  ease-in-out ${collapsed ? 'w-14' : 'w-[253px]'
          } bg-[#1A1D1F]`}>
        <nav className="flex flex-col justify-center gap-4 px-2 py-4 w-full">
          <Link
            to="#"
            className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base">
            <img
              src={smallLog}
              className="h-6 w-6 transition-all group-hover:scale-110"
              alt="Logo"
            />
            <span className="sr-only">Acme Inc</span>
          </Link>
          <TooltipProvider>
            {navigation.map((item, index) => (
              <Tooltip key={index}>
                <TooltipTrigger asChild>
                  <Link
                    to={item.href}
                    className={`flex items-center gap-4 px-2.5 h-9 w-full rounded-lg transition-colors hover:text-foreground md:h-8 ${item.current ? 'bg-accent text-accent-foreground' : 'text-muted-foreground'
                      }`}>
                    <span
                      className={`flex items-center ${collapsed ? 'w-full justify-center' : 'w-auto justify-start'}`}>
                      {item.icon}
                    </span>
                    <span className={`ml-2 w-full ${collapsed ? 'hidden' : 'block'}`}>
                      {item.name}
                    </span>
                  </Link>
                </TooltipTrigger>
                {collapsed && (
                  <TooltipContent side="right" className="bg-[#9747FF] text-white ">
                    {item.name}
                  </TooltipContent>
                )}
              </Tooltip>
            ))}
          </TooltipProvider>
        </nav>
        <button className="mt-auto mb-4 p-5 text-white w-full" onClick={handleToggle}>
          <CollapseIcon />
        </button>
      </aside>

      <div
        className={`flex-1 flex flex-col transition-all duration-300 ease-in-out ${collapsed ? 'ml-14' : 'ml-60'}`}>
        <header className="sticky top-0 z-40 flex h-14 items-center gap-4 border-b bg-[#161A27] px-4 sm:px-6">
          <Sheet>
            <SheetTrigger>
              <Button size="icon" variant="outline" className="sm:hidden">
                <PanelLeft className="h-5 w-5" />
                <span className="sr-only">Toggle Menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="sm:max-w-xs">
              <nav className="grid gap-6 text-lg font-medium">
                <Link
                  to="#"
                  className="group flex h-20 w-[80%] shrink-0 items-center justify-center gap-2 text-lg font-semibold text-primary-foreground md:text-base">
                  <img
                    src={largeLog}
                    className="h-50 w-80 transition-all group-hover:scale-110"
                    alt="Large Logo"
                  />
                  <span className="sr-only">Acme Inc</span>
                </Link>
                {navigation.map((item, index) => (
                  <Link
                    key={index}
                    to={item.href}
                    className={`flex items-center gap-4 px-2.5 ${item.current ? 'text-foreground' : 'text-muted-foreground'
                      } hover:text-foreground`}>
                    {item.icon}
                    {item.name}
                  </Link>
                ))}
              </nav>
            </SheetContent>
          </Sheet>
          <Breadcrumb className="hidden md:flex">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink
                  asChild
                  className={paths.length === 0 ? 'font-bold text-white' : ''}>
                  <Link to="/" className="text-[18px] manrope-fontCss">
                    Home
                  </Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              {paths.map((path, index) => {
                const to = `/${paths.slice(0, index + 1).join('/')}`;
                const isLast = index === paths.length - 1;
                return (
                  <BreadcrumbItem key={index}>
                    <BreadcrumbSeparator />
                    <BreadcrumbLink asChild className={isLast ? 'font-bold text-white' : ''}>
                      <Link className="text-[18px] manrope-fontCss" to={to}>
                        {path.charAt(0).toUpperCase() + path.slice(1)}
                      </Link>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                );
              })}
            </BreadcrumbList>
          </Breadcrumb>
          <div className="ml-auto flex items-center gap-4">
            <Button
              variant="ghost"
              className="text-white font-semibold text-lg flex gap-2 border-2 border-[#444444]"
              onClick={() => {
                handleButtonClick();
              }}>
              {userIcons[userType]}
              {userType}
            </Button>
            <Button variant="ghost" onClick={handleNotificationMenuToggle}>
              <Bell />
            </Button>
            <NotificationMenu
              open={isNotificationMenuOpen}
              onClose={handleClose}
              anchorEl={anchorEl}
            />
            <DropdownMenu>
              <DropdownMenuTrigger asChild className="bg=[#161A27]">
                <Button variant="outline" size="icon" className="overflow-hidden rounded-full">
                  <img
                    src={user?.user_image || noProfile}
                    width={36}
                    height={36}
                    alt="Avatar"
                    className="overflow-hidden rounded-full"
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-60 bg-[#161A27]">
                <DropdownMenuLabel className="text-lg font-semibold">
                  {user && user.user_name}
                </DropdownMenuLabel>
                <DropdownMenuLabel className="text-sm -mt-2">
                  {user && user.email}
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem className="text-base font-semibold" onClick={handleAccount}>
                  Account Details
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="cursor-pointer text-base font-semibold"
                  onClick={handleSubscriptions}>
                  My subscriptions
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="cursor-pointer justify-between text-base font-semibold"
                  onClick={onLogout}>
                  Logout
                  <LogOut color="#9747FF" />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </header>

        <main className="flex-1 h-screen overflow-y-auto p-4 py-1">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default withUser(Layout);
