import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { TextArea } from "../form/TextArea";
import { Button } from "../Button";


const CurlImportDialog = ({ isOpen, onClose, onSubmit,curlInput,setCurlInput }) => {
  

  const handleParseCurl = () => {
    if (onSubmit) {
      onSubmit(curlInput); // Pass the input to the parent component
    }
    setCurlInput(""); // Reset input
    onClose(); // Close the dialog
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          fontSize: "1.25rem",
          fontWeight: "600",
          backgroundColor: "#161A27",
          color: "white",
        }}
      >
        Paste Curl Command
      </DialogTitle>
      <DialogContent sx={{ padding: "16px", backgroundColor: "#161A27" }}>
        <TextArea
          fullWidth
          className="bg-transparent w-full p-1 rounded-md border px-4 border-gray-600 h-9 text-white min-h-36 overflow-scroll placeholder:text-white"
          id="curl"
          placeholder="Paste Curl Here"
          value={curlInput}
          onChange={(e) => setCurlInput(e.target.value)}
        />
        <div className="flex w-full justify-end gap-8 my-8">
          <Button
            type="button"
            onClick={onClose}
            size="sm"
            variant="destructive"
            className="bg-[#dc3545] hover:bg-[#c82333]"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleParseCurl}
            size="sm"
            variant="default"
            className="bg-[#9747FF]"
          >
            Paste
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CurlImportDialog;
