// Library imports
import { Save, Settings } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Popover, PopoverContent, PopoverTrigger } from "../components/Popover";

// Component imports
import { Button } from "../components/Button";
import { Label } from "./form/Label";

// Util imports
import { LOCAL_STORAGE_KEYS } from "../utils/constants";
import { renderField } from "../utils/formValidations";
import { getDataFromLocalStorage } from "../utils/helper";

const SettingsDropdown = () => {
	useEffect(() => {
		let timer = 5;
		timer = getDataFromLocalStorage(LOCAL_STORAGE_KEYS.ASYNC_TIMER_INFO);
		if (timer) {
			const time = JSON.parse(timer);
			setFormData({
				timer: time.timer,
			});
		}
	}, []);

	const required = (value) => (value ? undefined : "Required");
	const mustBeNumber = (value) => (isNaN(value) ? "Must be a number" : undefined);
	const minValue = (min) => (value) => (isNaN(value) || value >= min ? undefined : `Should be at least ${min}`);

	// Component States
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	const [formData, setFormData] = useState({
		timer: 5000,
	});

	const composeValidators =
		(...validators) =>
		(value) =>
			validators.reduce((error, validator) => error || validator(value), undefined);

	const onSubmit = (formData) => {
		localStorage.setItem(LOCAL_STORAGE_KEYS.ASYNC_TIMER_INFO, JSON.stringify(formData));
		setIsSettingsOpen(false);
		setFormData(formData);
	};

	return (
		<div className="mr-2 border-2 border-current p-1 rounded-lg cursor-pointer">
			<Popover open={isSettingsOpen}>
				<PopoverTrigger asChild>
					<Settings className="h-5 w-5" onClick={() => setIsSettingsOpen(!isSettingsOpen)} />
				</PopoverTrigger>
				<PopoverContent className="p-0">
					<div>
						<Form
							onSubmit={onSubmit}
							initialValues={formData}
							keepDirtyOnReinitialize
							render={({ handleSubmit, values }) => (
								<React.Fragment>
									<form onSubmit={handleSubmit}>
										<div className="p-4">
											<div className="font-semibold">
												<Label htmlFor="name" className={`mb-2 text-sm block text-left`}>
													Async API Timer (in seconds) *
												</Label>
												<Field
													className="bg-[#161A27]"
													id="timer"
													type="number"
													name="timer"
													placeholder="Enter timer in seconds"
													validate={composeValidators(required, mustBeNumber, minValue(5))}>
													{renderField}
												</Field>
											</div>
											<div className="flex justify-end">
												<Button type="submit" size="sm" variant="default" className="h-6 gap-1 text-sm mt-4">
													<Save className="h-3 w-3" stroke="white" />
													<span className="sr-only sm:not-sr-only text-white">Save</span>
												</Button>
											</div>
										</div>
									</form>
								</React.Fragment>
							)}
						/>
					</div>
				</PopoverContent>
			</Popover>
		</div>
	);
};

export default SettingsDropdown;
