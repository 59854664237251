// Library imports
import {  Save, Settings } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";


// Component imports



import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { useToast } from "./UseToast";
import Loader from "./Loader";
import { Button } from "./Button";
import { Label } from "./ui/label";
import { renderField } from "../helpers/form-validations";
import { uploadFileJira } from "../api/app";


// Util imports






const ReportPublishDropdown = ({filePath,testId}) => {
	const { toast } = useToast();
	const [loadingMessage] = useState("Publishing Report...")
	const [isLoading, setIsLoading] = useState(false);
	// useEffect(() => {
	// 	let timer = 5;
	// 	timer = getDataFromLocalStorage(LOCAL_STORAGE_KEYS.ASYNC_TIMER_INFO);
	// 	if (timer) {
	// 		const time = JSON.parse(timer);
	// 		setFormData({
	// 			timer: time.timer,
	// 		});
	// 	}
	// }, []);

	const required = (value) => (value ? undefined : "Required");
	// const mustBeNumber = (value) => (isNaN(value) ? "Must be a number" : undefined);
	// const minValue = (min) => (value) => (isNaN(value) || value >= min ? undefined : `Should be at least ${min}`);

	// Component States
	const [isReportPublishOpen, setIsReportPublishOpen] = useState(false);
	const [formData, setFormData] = useState({
		jira_key: "",
        // filePath:"1wZDUJMBY_SNpKPJukE_/1wZDUJMBY_SNpKPJukE__2024-11-21T19-47-20-073Z.pdf"
		filePath: filePath && filePath,

	});

	const composeValidators =
		(...validators) =>
		(value) =>
			validators.reduce((error, validator) => error || validator(value), undefined);

	const onSubmit = async(formData) => {
		setIsLoading(true)
		
		console.log("FILE PATH",filePath)
		
		
        console.log("jira ticket data",formData)
		const payload={
			jira_key:formData.jira_key,
           filePath:filePath
		}
        try {
			if(filePath===""){
				setIsLoading(false)
				toast({
					variant: "destructive",
					title: "Report Missing",
					description: `Report is not generated!`,
				  });
				return
			}
            if(formData ){
                const response= await uploadFileJira(payload)
                console.log("Repsonse for jira upload file",response)
				setIsLoading(false)
				toast({
					variant: "success",
					title: "Report publish",
					description: `report successfully uploaded in Jira`,
				  });
            }
            setFormData(formData);
        setIsReportPublishOpen(false);
        } catch (error) {
			setIsLoading(false)
            console.log("Error in jira upload file api",error)
        }
		
	};

	return (
		<div className="mr-2  border-current p-1 rounded-lg cursor-pointer">
			{isLoading && <Loader loadingMessage={loadingMessage} />}
			<Popover open={isReportPublishOpen}>
				<PopoverTrigger asChild>
					{/* <div className="h-5 w-5" onClick={() => setIsReportPublishOpen(!isReportPublishOpen)} >
                        Publish Report
                        </div> */}
                        <Button onClick={() => setIsReportPublishOpen(!isReportPublishOpen)} 
						
						size="lg" 
						variant="ghost"
                className="text-white h-10 w-60 text-base  bg-[#222938] hover:bg-[#222938] border-[#444444] border-2 hover:text-[#FFFFFF] flex items-center gap-2 font-bold"
						 >
						
						<span className="sr-only sm:not-sr-only text-white">Publish Report</span>
					</Button>
				</PopoverTrigger>
				<PopoverContent className="p-0">
					<div>
						<Form
							onSubmit={onSubmit}
							initialValues={formData}
							keepDirtyOnReinitialize
							render={({ handleSubmit, values }) => (
								<React.Fragment>
									<form onSubmit={handleSubmit}>
										<div className="p-4">
											<div className="font-semibold">
												<Label htmlFor="name" className={`mb-2 text-sm block text-left`}>
													Jira Ticket
												</Label>
												<Field
													className="bg-[#161A27]"
													id="jira_key"
													type="text"
													name="jira_key"
													placeholder="Enter Jira Ticket"
													validate={composeValidators(required)}>
													{renderField}
												</Field>
											</div>
											<div className="flex justify-end">
												<Button 
												type="submit"
												
												size="sm"
												 variant="default"
												  className="h-6 gap-1 text-sm mt-4">
													<Save className="h-3 w-3" stroke="white" />
													<span className="sr-only sm:not-sr-only text-white">Publish</span>
												</Button>
											</div>
										</div>
									</form>
								</React.Fragment>
							)}
						/>
					</div>
				</PopoverContent>
			</Popover>
		</div>
	);
};

export default ReportPublishDropdown;
