import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport
} from './Toast';
import { useToast } from './UseToast';
import successVector from '../assets/images/successVector.png';
import errorVector from '../assets/images/errorVector.png';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <img
                  alt=""
                  src={props.variant === 'success' ? successVector : errorVector}
                  className="w-3.5 h-3.5"
                />
                {title && <ToastTitle>{title}</ToastTitle>}
              </div>
              {description && <ToastDescription className="mt-1">{description}</ToastDescription>}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
