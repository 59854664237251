// Library imports
import React, { useRef } from 'react';
import { Play, Plus, Trash2 } from 'lucide-react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

// Component imports
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '../../components/Accordion';
import { Badge } from '../../components/Badge';
import { Button } from '../../components/Button';
import { Card, CardContent } from '../../components/Card';
import { Checkbox } from '../../components/form/Checkbox';
import { FileUploader } from '../../components/form/DropZone';
import { Input } from '../../components/form/Input';
import { Label } from '../../components/form/Label';
import { Switch } from '../../components/form/Switch';
import { TextArea } from '../../components/form/TextArea';
import TestResultsModal from '../../components/modals/TestResultsModal';

// Util imports
import { renderField } from '../../utils/formValidations';
import { getStatusColor, getStatusColorForDot } from '../../utils/helper';

import './accordion.css';

const TestCase = (props) => {
  const {
    values,
    composeValidators,
    addMoreTest,
    resultData,
    handleKeyValueBlurOrChange,
    fieldName,
    apiName,
    removeTestCase
  } = props;

  const required = (value) => (value ? undefined : 'Required');

  const testResultsModalRef = useRef();
  const openTestDetails = (event, resultData) => {
    event.preventDefault();

    if (testResultsModalRef.current?.setTestResultData) {
      testResultsModalRef.current.setTestResultData(resultData?.steps?.[0]?.checks?.jsonpath || {});
    }

    if (testResultsModalRef.current?.openModal) {
      testResultsModalRef.current.openModal();
    }
  };

  return (
    <React.Fragment>
      <FieldArray name={fieldName}>
        {({ fields }) => (
          <>
            <div className="flex items-center space-x-4 mb-4">
              <Label htmlFor="headers" className="text-lg font-bold">
                {`${fieldName === 'tests' ? 'Tests' : 'Async API Tests'}${apiName ? ' for ' + apiName : ''}`}
              </Label>
              <div className="icon-only-btn-green">
                <Plus
                  type="button"
                  onClick={() => addMoreTest(fields, values)}
                  className="focus:outline-none cursor-pointer"
                  size={18}
                />
              </div>
              <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
              {fieldName === 'tests' && (
                <Button type="submit" size="lg" variant="default" className="h-8 gap-1 text-sm">
                  <Play className="h-3.5 w-3.5" stroke="white" />
                  <span className="sr-only sm:not-sr-only text-white">Run All</span>
                </Button>
              )}
            </div>
            {fields.map((name, index) => (
              <div className="w-full mb-4" key={index}>
                <Card>
                  <CardContent>
                    <Accordion key={`Test ${index + 1}`} type="single" collapsible>
                      <AccordionItem value={`Test ${index + 1}`}>
                        <AccordionTrigger>
                          <div className="flex items-center">
                            <span>{`Test ${index + 1}`}</span>
                            {resultData && resultData[index] && (
                              <Badge
                                className={`ml-2 ${getStatusColor(`${resultData[index].passed === true ? 'success' : 'failed'}`)} padding-top text-sm font-semibold w-24 h-6 inline-flex items-start justify-start`}>
                                <div className="flex flex-row justify-center items-center">
                                  <div
                                    className={`${getStatusColorForDot(`${resultData[index].passed === true ? 'success' : 'failed'}`)} text-xs w-2 h-2 rounded-full mr-2 items-start `}
                                  />
                                  {`${resultData[index].passed === true ? 'Passed' : 'Failed'}`}
                                </div>
                              </Badge>
                            )}
                            {resultData?.[index]?.passed === false && (
                              <Button
                                onClick={(event) => openTestDetails(event, resultData[index])}
                                size="sm"
                                variant="link"
                                className="h-8 gap-1 text-sm">
                                <span className="sr-only sm:not-sr-only text-white">
                                  View test result
                                </span>
                              </Button>
                            )}
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="flex justify-between mb-4">
                            <div className="flex items-start">
                              <div className="mr-4">
                                <Label htmlFor="name" className={`mb-2 text-xs block text-left`}>
                                  Name *
                                </Label>
                                <Field
                                  name={`${name}.name`}
                                  placeholder="Enter name"
                                  id={`${name}.name` + index}
                                  className="border p-2 rounded bg-[#161A27] w-80"
                                  type="text"
                                  validate={composeValidators(required)}>
                                  {renderField}
                                </Field>
                              </div>
                              <div className="flex font-semibold items-center flex mt-8">
                                <Field name={`${name}.isBodyDynamic`}>
                                  {({ input }) => (
                                    <div>
                                      <Switch
                                        className="bg-background"
                                        label={input.value ? 'Dynamic' : 'Static'}
                                        checked={input.value}
                                        onCheckedChange={(e) => {
                                          input.onChange(e);
                                        }}
                                      />
                                    </div>
                                  )}
                                </Field>
                                <Label htmlFor="headers" className="ml-4 text-xs font-bold">
                                  Upload From File
                                </Label>
                              </div>
                            </div>
                            <div className=" mt-6">
                              <Button
                                onClick={(event) => {
                                  event.preventDefault();
                                  removeTestCase(index, fields, values);
                                }}
                                size="lg"
                                variant="destructive"
                                className="ml-4 h-8 gap-1 text-sm">
                                <Trash2 className="h-3.5 w-3.5" stroke="white" />
                                <span className="sr-only sm:not-sr-only text-white">Delete</span>
                              </Button>
                            </div>
                          </div>
                          <div className="grid grid-cols-12 gap-4 mb-4">
                            <div className="col-span-4 font-semibold flex items-center space-x-4">
                              <Field
                                name={`${name}.showParamsField`}
                                id={'showParamsField-' + index}
                                className="border mr-2 rounded bg-[#161A27] h-5 w-1/12"
                                component={Checkbox}
                                type="checkbox"
                                validate={composeValidators()}>
                                {renderField}
                              </Field>
                              Path/Query Param String
                            </div>
                            {fields.value[index].showParamsField && (
                              <div className="col-span-8 font-semibold flex items-center space-x-4">
                                <Field
                                  name={`${name}.paramsString`}
                                  render={({ input }) => (
                                    <Input
                                      {...input}
                                      placeholder="Enter"
                                      className="border p-2 rounded bg-[#161A27]"
                                      onChange={(e) => {
                                        input.onChange(e);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            )}
                          </div>
                          <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12">
                              <Label htmlFor="name" className={`mb-2 text-sm block text-left`}>
                                Request Body
                              </Label>
                              {values[fieldName][index].isBodyDynamic ? (
                                <Field
                                  name={`${name}.requestBody`}
                                  id={'requestBody-' + index}
                                  render={({ input }) => {
                                    return (
                                      <FileUploader
                                        onValueChange={(value) => {
                                          const e = {
                                            target: {
                                              value: value
                                            }
                                          };
                                          input.onChange(e);
                                        }}
                                      />
                                    );
                                  }}
                                />
                              ) : (
                                <Field
                                  name={`${name}.requestBody`}
                                  className="w-full rounded bg-transparent border"
                                  id={'requestBody-' + index}
                                  type="textarea"
                                  rows="5"
                                  component={TextArea}
                                  validate={composeValidators()}>
                                  {renderField}
                                </Field>
                              )}
                            </div>
                            <div className="col-span-12">
                              <Label htmlFor="name" className={`mb-2 text-sm block text-left`}>
                                Expected result
                              </Label>
                              {values[fieldName]?.[index]?.isBodyDynamic ? (
                                <Field
                                  name={`${name}.responseBody`}
                                  id={'responseBody-' + index}
                                  render={({ input }) => {
                                    return (
                                      <FileUploader
                                        onValueChange={(value) => {
                                          const e = {
                                            target: {
                                              value: value
                                            }
                                          };
                                          input.onChange(e);
                                        }}
                                      />
                                    );
                                  }}
                                />
                              ) : (
                                <Field
                                  name={`${name}.responseBody`}
                                  className="w-full rounded bg-transparent border"
                                  id={'responseBody-' + index}
                                  type="textarea"
                                  rows="5"
                                  component={TextArea}
                                  validate={composeValidators()}>
                                  {renderField}
                                </Field>
                              )}
                            </div>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </CardContent>
                </Card>
              </div>
            ))}
          </>
        )}
      </FieldArray>

      <TestResultsModal ref={testResultsModalRef} />
    </React.Fragment>
  );
};

export default TestCase;
