import axios from "axios";
import { store } from "../redux/store";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT_API_AUTOMATION}/api`,
 headers:{
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("apiAutomationAccessToken")}`,
 }

});
const instance1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT_API_AUTOMATION}/api`,
  // baseURL:`https://qmu5hj-ip-117-251-46-0.tunnelmole.net/api/`,
});

const instanceForLLM = axios.create({
  // baseURL: `https://o9zy2f-ip-182-60-28-251.tunnelmole.net/api/`
  baseURL:process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT

});

axios.interceptors.request.use(
  (config) => {
    const state = store.getState(); // Access Redux state
    const token = state?.user?.apiAutomationAccessToken; // Adjust this path based on your Redux state structure
   
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const setAuthHeader = (token) => {
  instance.defaults.headers = {
    ...instance.defaults.headers,
    Authorization: `Bearer ${token}`
  }
  
}



axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});


const getTestRecords = (params) => {
  return instance.get("tests", {
    params
  })
}

const runTests = (data) => {
  return instance.post("verify", data)
}

const saveAsyncTest = (apiTestId, data) => {
  return instance.patch(`save-async-workflow/${apiTestId}`, data)
}

const getTestById = (testId) => {
  return instance.get(`test/${testId}`)
}

const createIssue=(issueData)=>{
return instance.post(`jira/create-issue`,issueData)
}

const uploadFileJira=(payload)=>{
  return instance.post("jira/upload-file",payload)
}

const getReportFilePath = (payload) => {
  return instance.post("report-url", payload)

}






const getSolutionForApiAutomation=(payload)=>{
  return instanceForLLM.post(`restapisolution`,payload)
}

const getSolutionForLoadTest=(payload)=>{
  return instanceForLLM.post(`loadtestsolution`,payload)
}

const addCommentInJira=(payload)=>{
  return instance.post(`jira/add-comment`,payload)
}

const getSolutionForSecuritySolution=(payload)=>{
  return instanceForLLM.post(`securitytestsolution`,payload)
}



export {
  getTestRecords,
  runTests,
  saveAsyncTest,
  getTestById,
  createIssue,
  uploadFileJira,
  getReportFilePath,
  getSolutionForApiAutomation,
  addCommentInJira,
  getSolutionForLoadTest,
  getSolutionForSecuritySolution,
  setAuthHeader
}