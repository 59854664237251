import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Svg, Line } from '@react-pdf/renderer';
import rightImage from '../../assets/images/PdfImage1.png';
import topImage from '../../assets/images/PdfImage2.png';
import leftImage from '../../assets/images/PdfImage3.png';
import convertPToText from '../../utils/convertHtmlToReactPdfElements';
// import DisplayObject from '../../utils/displayInstance';
import RiskImage from '../../utils/RiskImage';

// Font.register({
//   family: "Manrope",
//   fonts: [
//     {
//       src: `../assets/Manrope-ExtraLight.ttf`,
//       fontWeight: 200,
//     },
//     {
//       src: `../assets/Manrope-Light.ttf`,
//       fontWeight: 300,
//     },
//     {
//       src: `../assets/Manrope-Regular.ttf`,
//       fontWeight: 400,
//     },
//     {
//       src: `../assets/Manrope-Medium.ttf`,
//       fontWeight: 500,
//     },
//     {
//       src: `../assets/Manrope-SemiBold.ttf`,
//       fontWeight: 600,
//     },
//     {
//       src: `../assets/Manrope-Bold.ttf`,
//       fontWeight: 700,
//     },
//     {
//       src: `../assets/Manrope-ExtraBold.ttf`,
//       fontWeight: 800,
//     },
//   ],
// });

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: 30,
    display: 'flex',
    justifyContent: 'center',
    color: '#333333'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '90%',
    marginTop: 80,
    marginLeft: 20
  },
  header: {
    fontSize: 16,
    color: 'grey',
    textAlign: 'left',
    marginTop: 200
  },
  title: {
    fontSize: 36
  },
  report: {
    fontSize: 36,
    color: '#9747FF'
  },
  subtitle: {
    fontSize: 24
  },
  preparedBy: {
    fontSize: 16,
    color: 'grey',
    marginTop: 50
  },

  footer: {
    fontSize: 16,
    marginTop: 350
  },
  overviewPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  overviewHeader: {
    fontSize: 18,
    textAlign: 'right',
    marginBottom: 10
  },
  overviewTitle: {
    marginTop: 30,
    marginLeft: 20,
    fontSize: 30
  },
  overviewList: {
    marginTop: 30,
    marginLeft: 30,
    fontSize: 16,
    lineHeight: 1.5,
    width: '350px'
  },
  overviewListItem: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  overviewFooterImage: {
    width: 50,
    height: 50,
    alignSelf: 'flex-end'
  },
  footerText: {
    fontSize: 12
  },
  split: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  rightImage: {
    width: '600px',
    height: '600px',
    marginLeft: -150,
    marginBottom: -150
  },
  rightContainer: {
    marginBottom: 600,
    marginTop: -30,
    marginLeft: -150,
    height: '100%'
  },
  overviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  topImage: {
    top: 0,
    position: 'fixed',
    height: '50px',
    width: '900px',
    marginLeft: -30,
    marginTop: -30
  },
  leftImage: {
    left: 0,
    width: '100%',
    marginLeft: -100
  },
  bottomImage: {
    width: '900px',
    height: '50px',
    marginLeft: -30,
    marginBottom: -30
  },
  page3: {
    backgroundColor: '#ffffff',
    padding: 30,
    display: 'flex',
    color: '#333333'
  }
});

const risk = [
  { id: "1", value: "Low" },
  { id: "2", value: "Medium" },
  { id: "3", value: "High" },
  { id: '0', value: 'Informational' },
];

const metricsStyles = StyleSheet.create({
  container: {
    width: "500px",
    height: "150px",
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
    marginTop: "100px",
    border: "1px black solid",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    boxSizing: "border-box", // Ensure padding is included within the element's size
    borderRadius: 3,
  }
,  
  metricRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 6,
    marginBottom: 12,
    width:"100%",
   
  },
  metricBox: {
    width: '20%',
    border: '1px solid #CBD5E1',
    borderRadius: 10,
    padding: 12,
    textAlign: 'center',
  },
  metricLabel: {
    fontSize: 10,
    color: '#64748B',
    marginBottom: 4,
  },
  metricValue: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F1F5F9',
    height: 6,
    borderRadius: 3,
    overflow: 'hidden',
  },
  bar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 8,
    color: 'white',
  },
  medBar: {
    width: '50%',
    backgroundColor: '#64748B',
  },
  lowBar: {
    width: '50%',
    backgroundColor: '#94A3B8',
  },
});

const Metrics = ({data}) => (
  <View style={metricsStyles.container}  >
    {/* Metrics Row */}
    <View style={metricsStyles.metricRow}>
      {/* <View style={metricsStyles.metricBox}>
        <Text style={metricsStyles.metricLabel}>Critical</Text>
        <Text style={metricsStyles.metricValue}>0</Text>
      </View> */}
      <View style={metricsStyles.metricBox}>
        <Text style={metricsStyles.metricLabel}>High</Text>
        <Text style={metricsStyles.metricValue}>{ data?.filter(item => item.risk === "High" || item.riskcode==="3").length || 0}</Text>
      </View>
      <View style={metricsStyles.metricBox}>
        <Text style={metricsStyles.metricLabel}>Medium</Text>
        <Text style={metricsStyles.metricValue}>{data?.filter(item => item.risk === "Medium" || item.riskcode==="2").length || 0}</Text>
      </View>
      <View style={metricsStyles.metricBox}>
        <Text style={metricsStyles.metricLabel}>Low</Text>
        <Text style={metricsStyles.metricValue}>{data?.filter(item => item.risk === "Low" || item.riskcode==="1").length || 0}</Text>
      </View>
      <View style={metricsStyles.metricBox}>
        <Text style={metricsStyles.metricLabel}>Informational</Text>
        <Text style={metricsStyles.metricValue}>{data?.filter(item => item.risk === "Informational" || item.riskcode==="0").length || 0}</Text>
      </View>
      {/* <View style={metricsStyles.metricBox}>
        <Text style={metricsStyles.metricLabel}>Accepted</Text>
        <Text style={metricsStyles.metricValue}>0</Text>
      </View> */}
    </View>

    {/* Bar Chart
    <View style={metricsStyles.barContainer}>
      <View style={[metricsStyles.bar, metricsStyles.medBar]}>
        <Text>50%</Text>
      </View>
      <View style={[metricsStyles.bar, metricsStyles.lowBar]}>
        <Text>50%</Text>
      </View>
    </View> */}
  </View>
);


const SecurityResultPdf = ({ data ,type,fullData}) => {
  //   console.log(data);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.split}>
          <View style={styles.container}>
            <View>
              <Text style={styles.header} fontWeight={'bold'}>
                
              </Text>
              <Text style={styles.title}>Vulnerability Scan</Text>
              <Text style={styles.report}>Report</Text>
            </View>
            {/* <View>
              <Text style={styles.footer}>hostedscan.com</Text>
            </View> */}
          </View>
          <View style={styles.rightContainer}>
            <Image style={styles.rightImage} src={rightImage} />
          </View>
        </View>
      </Page>

      {/* Overview Page */}
      {/* <Page size="A4" style={styles.page}>
        <View style={styles.overviewPageContainer}>
          <View style={styles.overviewContainer}>
            <View>
              <Image style={styles.topImage} src={topImage} />
              <View style={styles.split}>
                <Image style={styles.leftImage} src={leftImage} />
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text style={styles.overviewTitle}>Overview</Text>
                  <Svg height="1" width="800" style={{ marginTop: 10 }}>
                    <Line
                      x1="0"
                      y1="0"
                      x2="1000"
                      y2="0"
                      strokeWidth={2}
                      stroke="#9747FF"
                      opacity="0.2"
                    />
                  </Svg>
                  <View style={styles.overviewList}>
                    {[
                      { title: 'Executive Summary', page: 3 },
                      { title: 'Vulnerabilities By Target', page: 4 },
                      {
                        title: 'Active Web Application Vulnerabilities',
                        page: 7
                      },
                      {
                        title: 'Passive Web Application Vulnerabilities',
                        page: 17
                      },
                      { title: 'SSL/TLS Security', page: 26 },
                      { title: 'Network Vulnerabilities', page: 27 },
                      { title: 'Open TCP Ports', page: 28 },
                      { title: 'Open UDP Ports', page: 31 },
                      { title: 'Glossary', page: 32 }
                    ].map((item, index) => (
                      <View key={index} style={styles.overviewListItem}>
                        <Text>
                          {index + 1}. {item.title}
                        </Text>
                        <Text style={{ color: '#9747FF' }}>{item.page}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
              <Image style={styles.bottomImage} src={topImage} />
            </View>
          </View>
        </View>
      </Page> */}

      <Page size="A4" style={styles.page}>
        <View style={styles.overviewPageContainer}>
          <View style={styles.overviewContainer}>
            <View>
           
              <View style={styles.split}>
         
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <View style={{ display: 'flex', flexDirection: 'row' }} >
                  <Text style={{fontSize:"20px",color: '#95a2b8',marginRight: '10'}} >1</Text>
                  <Text style={{ fontSize: '20px' }}>Executive Summary</Text>
                  </View>
                  {/* <Svg height="1" width="800" style={{ marginTop: 10 }}>
                    <Line
                      x1="0"
                      y1="0"
                      x2="1000"
                      y2="0"
                      strokeWidth={2}
                      stroke="#9747FF"
                      opacity="0.2"
                    />
                  </Svg> */}
                  <View style={{ display: 'flex', flexDirection: 'row' }} >

                    <Text style={{ fontSize: 12,color: '#95a2b8',marginTop: '10' }} >
                    Vulnerability scans were conducted on select servers, networks, websites, and applications. This report contains the discovered potential vulnerabilities from these scans. Vulnerabilities have been classified by severity. Higher severity indicates a greater risk of a data breach, loss of integrity, or availability of the targets.
                    </Text>
                  </View>
         
                 <View>

               <View style={{ display: 'flex', flexDirection: 'row',marginTop:"20" }} >
               <Text style={{fontSize:"20px",color: '#95a2b8',marginRight: '10'}} >1.1</Text>
               <Text style={{ fontSize: '20px' }}>Total Vulnerabilities</Text>

               </View>
                 </View>
               
                  <View>

                    <Text style={{ fontSize: 12,color: '#95a2b8',marginTop: '10' }} >
                    Below are the total number of vulnerabilities found by Risk. Critical vulnerabilities are the most severe and should be evaluated first. An accepted vulnerability is one which has been manually reviewed and classified as acceptable to not fix at this time, such as a false positive detection or an intentional part of the systems architecture.
                     </Text>
                  </View>
                   {/* Add the metrics component */}
                <Metrics type={type} data={data} />
                </View>
              </View>
            
            </View>
          </View>
        </View>
      </Page> 

      {data?.filter(alert => alert.risk === "High" || alert.riskcode==="3").map((alert, idx) => (
  <Page size="A4" style={styles.page3} key={idx}>
    {idx === 0 && (
      <>
        <View style={styles.section_details}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={{
                color: '#95a2b8',
                marginRight: '10',
                fontSize: '20px'
              }}>
              2
            </Text>
            <Text style={{ fontSize: '20px' }}>{type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"}</Text>
          </View>
        </View>

        <Svg height="1" width="800" style={{ marginTop: 30 }}>
          <Line
            x1="0"
            y1="0"
            x2="520"
            y2="0"
            strokeWidth={2}
            stroke="#95a2b8"
            opacity="0.2"
          />
        </Svg>
      </>
    )}

    <View style={{ marginTop: 30 }}>
      <RiskImage
        risk={
          alert?.risk === 'Low' || alert?.riskcode === "1"
            ? 1
            : alert?.risk === 'Medium' || alert?.riskcode === "2"
              ? 2
              : alert?.risk === 'High' || alert?.riskcode === "3"
                ? 3
                : alert?.risk === 'Extreme' || alert?.riskcode === "4"
                  ? 4
                  : 0
        }
      />
     
     <Text style={{ marginTop: -246, marginLeft: 50, fontSize: '22px' }}>
  <Text style={{ color: '#95a2b8', fontSize: '20px' }}>2.</Text> 
  <Text style={{ color: '#95a2b8', marginRight: '100px', fontSize: '20px' }}>
    {idx+1}
  </Text>
  {/* {'  '}{alert.name} */}
  {'  '}{alert.alert}
</Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 50,
          gap: 30,
          marginTop: 15
        }}>
        <View>
          <Text style={{ fontSize: 10, color: '#95a2b8' }}>SEVERITY</Text>
          <Text style={{ fontSize: 12 }}>{type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"}</Text>
        </View>
      </View>
    </View>

    <Svg height="1" width="800" style={{ marginTop: 30 }}>
      <Line x1="0" y1="0" x2="520" y2="0" strokeWidth={2} stroke="#95a2b8" opacity="0.2" />
    </Svg>

    <View style={{ marginTop: 30, gap: 20 }}>
      <View>
        <Text style={{ fontSize: 14 }}>Description: </Text>
        {type !== "security-api-scan" && convertPToText(alert?.description, true)}
        {type === "security-api-scan" && convertPToText(alert?.desc, true)}
      </View>

      <View>
        <Text style={{ fontSize: 14 }}>References: </Text>
        {convertPToText(alert.reference, true)}
      </View>
    </View>
  </Page>
))}

{/* {data?.filter(alert => alert.risk === "High").length > 0 ? (
  data?.filter(alert => alert.risk === "High").map((alert, idx) => (
    <Page size="A4" style={styles.page3} key={idx}>
      {idx === 0 && (
        <>
          <View style={styles.section_details}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text
                style={{
                  color: '#95a2b8',
                  marginRight: '10',
                  fontSize: '20px'
                }}>
                2
              </Text>
              <Text style={{ fontSize: '20px' }}>{alert.risk}</Text>
            </View>
          </View>

          <Svg height="1" width="800" style={{ marginTop: 30 }}>
            <Line
              x1="0"
              y1="0"
              x2="520"
              y2="0"
              strokeWidth={2}
              stroke="#95a2b8"
              opacity="0.2"
            />
          </Svg>
        </>
      )}

      <View style={{ marginTop: 30 }}>
        <RiskImage
          risk={
            alert?.risk === 'Low' || alert?.riskcode === "1"
              ? 1
              : alert?.risk === 'Medium' || alert?.riskcode === "2"
                ? 2
                : alert?.risk === 'High' || alert?.riskcode === "3"
                  ? 3
                  : alert?.risk === 'Extreme' || alert?.riskcode === "4"
                    ? 4
                    : 0
          }
        />

        <Text style={{ marginTop: -246, marginLeft: 50, fontSize: '22px' }}>
          <Text style={{ color: '#95a2b8', fontSize: '20px' }}>2.</Text> 
          <Text style={{ color: '#95a2b8', marginRight: '100px', fontSize: '20px' }}>
            {idx+1}
          </Text>
          {'  '}{alert.name}
        </Text>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 50,
            gap: 30,
            marginTop: 15
          }}>
          <View>
            <Text style={{ fontSize: 10, color: '#95a2b8' }}>SEVERITY</Text>
            <Text style={{ fontSize: 12 }}>{type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"}</Text>
          </View>
        </View>
      </View>

      <Svg height="1" width="800" style={{ marginTop: 30 }}>
        <Line x1="0" y1="0" x2="520" y2="0" strokeWidth={2} stroke="#95a2b8" opacity="0.2" />
      </Svg>

      <View style={{ marginTop: 30, gap: 20 }}>
        <View>
          <Text style={{ fontSize: 14 }}>Description: </Text>
          {type !== "security-api-scan" && convertPToText(alert?.description, true)}
          {type === "security-api-scan" && convertPToText(alert?.desc, true)}
        </View>

        <View>
          <Text style={{ fontSize: 14 }}>References: </Text>
          {convertPToText(alert.reference, true)}
        </View>
      </View>
    </Page>
  ))
) : (
  <Text>No data found in the {type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"} Risk category for the test</Text>
)} */}


{data?.filter(alert => alert.risk === "Medium" || alert.riskcode==="2").map((alert, idx) => (
  <Page size="A4" style={styles.page3} key={idx}>
    {idx === 0 && (
      <>
        <View style={styles.section_details}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={{
                color: '#95a2b8',
                marginRight: '10',
                fontSize: '20px'
              }}>
              3
            </Text>
            <Text style={{ fontSize: '20px' }}>{type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"}</Text>
          </View>
        </View>

        <Svg height="1" width="800" style={{ marginTop: 30 }}>
          <Line
            x1="0"
            y1="0"
            x2="520"
            y2="0"
            strokeWidth={2}
            stroke="#95a2b8"
            opacity="0.2"
          />
        </Svg>
      </>
    )}

    <View style={{ marginTop: 30 }}>
      <RiskImage
        risk={
          alert?.risk === 'Low' || alert?.riskcode === "1"
            ? 1
            : alert?.risk === 'Medium' || alert?.riskcode === "2"
              ? 2
              : alert?.risk === 'High' || alert?.riskcode === "3"
                ? 3
                : alert?.risk === 'Extreme' || alert?.riskcode === "4"
                  ? 4
                  : 0
        }
      />
  <Text style={{ marginTop: -246, marginLeft: 50, fontSize: '22px' }}>
  <Text style={{ color: '#95a2b8', fontSize: '20px' }}>3.</Text> 
  <Text style={{ color: '#95a2b8', marginRight: '100px', fontSize: '20px' }}>
    {idx+1}
  </Text>
  {/* {'  '}{alert.name} */}
  {'  '}{alert.alert}

</Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 50,
          gap: 30,
          marginTop: 15
        }}>
        <View>
          <Text style={{ fontSize: 10, color: '#95a2b8' }}>SEVERITY</Text>
          <Text style={{ fontSize: 12 }}>{type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"}</Text>
        </View>
      </View>
    </View>

    <Svg height="1" width="800" style={{ marginTop: 30 }}>
      <Line x1="0" y1="0" x2="520" y2="0" strokeWidth={2} stroke="#95a2b8" opacity="0.2" />
    </Svg>

    <View style={{ marginTop: 30, gap: 20 }}>
      <View>
        <Text style={{ fontSize: 14 }}>Description: </Text>
        {type !== "security-api-scan" && convertPToText(alert?.description, true)}
        {type === "security-api-scan" && convertPToText(alert?.desc, true)}
      </View>

      <View>
        <Text style={{ fontSize: 14 }}>References: </Text>
        {convertPToText(alert.reference, true)}
      </View>
    </View>
  </Page>
))}



{data?.filter(alert => alert.risk === "Low" || alert.riskcode==="1").map((alert, idx) => (
  <Page size="A4" style={styles.page3} key={idx}>
    {idx === 0 && (
      <>
        <View style={styles.section_details}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={{
                color: '#95a2b8',
                marginRight: '10',
                fontSize: '20px'
              }}>
              4
            </Text>
            <Text style={{ fontSize: '20px' }}>{type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"}</Text>
          </View>
        </View>

        <Svg height="1" width="800" style={{ marginTop: 30 }}>
          <Line
            x1="0"
            y1="0"
            x2="520"
            y2="0"
            strokeWidth={2}
            stroke="#95a2b8"
            opacity="0.2"
          />
        </Svg>
      </>
    )}

    <View style={{ marginTop: 30 }}>
      <RiskImage
        risk={
          alert?.risk === 'Low' || alert?.riskcode === "1"
            ? 1
            : alert?.risk === 'Medium' || alert?.riskcode === "2"
              ? 2
              : alert?.risk === 'High' || alert?.riskcode === "3"
                ? 3
                : alert?.risk === 'Extreme' || alert?.riskcode === "4"
                  ? 4
                  : 0
        }
      />
       <Text style={{ marginTop: -246, marginLeft: 50, fontSize: '22px' }}>
  <Text style={{ color: '#95a2b8', fontSize: '20px' }}>4.</Text> 
  <Text style={{ color: '#95a2b8', marginRight: '100px', fontSize: '20px' }}>
    {idx+1}
  </Text>
  {/* {'  '}{alert.name} */}
  {'  '}{alert.alert}
  </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 50,
          gap: 30,
          marginTop: 15
        }}>
        <View>
          <Text style={{ fontSize: 10, color: '#95a2b8' }}>SEVERITY</Text>
          <Text style={{ fontSize: 12 }}>{type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"}</Text>
        </View>
      </View>
    </View>

    <Svg height="1" width="800" style={{ marginTop: 30 }}>
      <Line x1="0" y1="0" x2="520" y2="0" strokeWidth={2} stroke="#95a2b8" opacity="0.2" />
    </Svg>

    <View style={{ marginTop: 30, gap: 20 }}>
      <View>
        <Text style={{ fontSize: 14 }}>Description: </Text>
        {type !== "security-api-scan" && convertPToText(alert?.description, true)}
        {type === "security-api-scan" && convertPToText(alert?.desc, true)}
      </View>

      <View>
        <Text style={{ fontSize: 14 }}>References: </Text>
        {convertPToText(alert.reference, true)}
      </View>
    </View>
  </Page>
))}


{data?.filter(alert => alert.risk === "Informational" || alert.riskcode==="0").map((alert, idx) => (
  <Page size="A4" style={styles.page3} key={idx}>
    {idx === 0 && (
      <>
        <View style={styles.section_details}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={{
                color: '#95a2b8',
                marginRight: '10',
                fontSize: '20px'
              }}>
              2
            </Text>
            <Text style={{ fontSize: '20px' }}>{type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"}</Text>
          </View>
        </View>

        <Svg height="1" width="800" style={{ marginTop: 30 }}>
          <Line
            x1="0"
            y1="0"
            x2="520"
            y2="0"
            strokeWidth={2}
            stroke="#95a2b8"
            opacity="0.2"
          />
        </Svg>
      </>
    )}

    <View style={{ marginTop: 30 }}>
      <RiskImage
        risk={
          alert?.risk === 'Low' || alert?.riskcode === "1"
            ? 1
            : alert?.risk === 'Medium' || alert?.riskcode === "2"
              ? 2
              : alert?.risk === 'High' || alert?.riskcode === "3"
                ? 3
                : alert?.risk === 'Extreme' || alert?.riskcode === "4"
                  ? 4
                  : 0
        }
      />
     
     <Text style={{ marginTop: -246, marginLeft: 50, fontSize: '22px' }}>
  <Text style={{ color: '#95a2b8', fontSize: '20px' }}>2.</Text> 
  <Text style={{ color: '#95a2b8', marginRight: '100px', fontSize: '20px' }}>
    {idx+1}
  </Text>
  {/* {'  '}{alert.name} */}
  {'  '}{alert.alert}
</Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 50,
          gap: 30,
          marginTop: 15
        }}>
        <View>
          <Text style={{ fontSize: 10, color: '#95a2b8' }}>SEVERITY</Text>
          <Text style={{ fontSize: 12 }}>{type !== "security-api-scan" ? alert.risk : risk.find((r) => r.id === alert.riskcode)?.value || "Unknown"}</Text>
        </View>
      </View>
    </View>

    <Svg height="1" width="800" style={{ marginTop: 30 }}>
      <Line x1="0" y1="0" x2="520" y2="0" strokeWidth={2} stroke="#95a2b8" opacity="0.2" />
    </Svg>

    <View style={{ marginTop: 30, gap: 20 }}>
      <View>
        <Text style={{ fontSize: 14 }}>Description: </Text>
        {type !== "security-api-scan" && convertPToText(alert?.description, true)}
        {type === "security-api-scan" && convertPToText(alert?.desc, true)}
      </View>

      <View>
        <Text style={{ fontSize: 14 }}>References: </Text>
        {convertPToText(alert.reference, true)}
      </View>
    </View>
  </Page>
))}
    </Document>
  );
};

export default SecurityResultPdf;
