// Library imports
import arrayMutators from 'final-form-arrays';

import { Download } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { generatePath, useParams } from 'react-router-dom';
import SplitPane, { Pane } from 'react-split-pane';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// Component imports
import BackButton from '../../components/BackButton';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Checkbox } from '../../components/form/Checkbox';
import { Label } from '../../components/form/Label';
import Loader from '../../components/Loader';
import SettingsDropdown from '../../components/Settings';
import { useToast } from '../../components/UseToast';
import Headers from './Headers';
import TestCase from './TestCase';

// Util imports
import { DEFAULT_USER_GROUP, LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { renderField } from '../../utils/formValidations';
import {
  deepClone,
  getDataFromLocalStorage,
  getParamStringFromURL,
  unFlattenJSON
} from '../../utils/helper';
import { JSONBuilder } from '../../utils/jsonBuilder';
// Constants imports
import { API_TYPES, AUTH_TYPES, HTTP_METHODS } from '../../utils/constants';

import ReportPublishDropdown from '../../components/reportPublishDropdown';

import { TextArea } from '../../components/form/TextArea';

import {
  runTests,
  saveAsyncTest,
  getTestById,
  getReportFilePath,
  uploadFileJira,
  createIssue,
  getSolutionForApiAutomation,
  addCommentInJira
} from '../../api/app';
import { Switch } from '../../components/ui/switch-no-label';
import { TextField } from '@mui/material';
import { user } from '../../redux/reducers';
import { VercelLogoIcon } from '@radix-ui/react-icons';

const TestForm = () => {
  const [errorsArray2, setErrorsArray2] = useState([]);
  const collapsed = useSelector((state) => state.toggle);
  const selectedGroupId = useSelector((state) => state.selectedGroupId);

  const [isCurlOpen, setIsCurlOpen] = useState(false);
  const [issueCreatedSuccessfullyDialog, setIssueCreatedSuccessfullyDialog] = useState(false);
  const [isCreateIssue, setISCreateIssue] = useState(false);
  const [filePath, setFilePath] = useState('');
  // React Hooks
  const { toast } = useToast();
  let { type, testId } = useParams();

  useEffect(() => {
    if (type === API_TYPES.ASYNC) {
      setPaneSize(2);
      setIsSyncAPI(false);
    }
    setAPIType(type);
    if (testId) {
      loadAPIData(testId);
      setAPIId(testId);
      setFilePath();
    }
  }, []);

  useEffect(() => {
    if (type === API_TYPES.SYNC) {
      if (collapsed) {
        setScreenWidth(window.screen.width - 266);
      } else {
        setScreenWidth(window.screen.width - 210);
      }
    }
  }, [collapsed]);

  const LOAD_TEST_AUTH_TYPE = AUTH_TYPES;
  const ALGORITHM_OPTIONS = [
    { value: 'md5', label: 'MD5' },
    { value: 'sha256', label: 'SHA256' },
    { value: 'sha756', label: 'SHA756' }
  ];

  const defaultHeaderObj = {
    key: '',
    value: ''
  };

  const defaultTestObj = {
    name: '',
    requestBody: '',
    responseBody: '',
    isBodyDynamic: false,
    showParamsField: false,
    paramsString: ''
  };

  // Component States
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [paneSize, setPaneSize] = useState(1);
  const [authType, setAuthType] = useState('');
  const [asyncAuthType, setAsyncAuthType] = useState('');
  const [backButtonRouteName] = useState('/APIAutomation');
  const [apiType, setAPIType] = useState(API_TYPES.SYNC);
  const [isSyncAPI, setIsSyncAPI] = useState(true);
  // State to hold the response from API
  const [apiResponse, setApiResponse] = useState('');
  const [resultData, setResultData] = useState([]);
  const [asyncResultData, setAsyncResultData] = useState([]);
  const [APIId, setAPIId] = useState('');
  const [reportFilePath, setReportFilePath] = useState('');
  const [jiraKey, setJiraKey] = useState();
  const [defaultFormData] = useState({
    // Fields for sync api type
    name: '',
    url: '',
    headerValues: [{ ...defaultHeaderObj }],
    httpMethod: '',
    authType: '',
    tests: [{ ...defaultTestObj }],
    // Fields for async api types
    asyncAPIName: '',
    asyncAPIHttpMethod: 'get',
    asyncAPIEndPointURL: '',
    asyncAPITests: [{ ...defaultTestObj }],
    asyncAuthType: '',
    asyncBearerToken: '',
    asyncUsername: '',
    asyncPassword: '',
    asyncHeaderValues: [{ ...defaultHeaderObj }]
  });
  const [formData, setFormData] = useState(deepClone(defaultFormData));
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Methods
  const required = (value) => (value ? undefined : 'Required');

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined);

  const handleMethodChange = (newMethod) => {
    setFormData((prevState) => ({
      ...prevState,
      httpMethod: newMethod
    }));
  };

  const loadAPIData = async (testId) => {
    setLoadingMessage('Loading test data...');
    setIsLoading(true);
    try {
      const response = await getTestById(testId);
      if (response?.data?.filePath) {
        setReportFilePath(response.data?.filePath);
      }
      let json = deepClone(defaultFormData);
      if (response.data?.sync) {
        json.name = response.data.sync?.workflow.name;
        json.url = response.data.sync?.workflow.env.host;
        json.httpMethod = response.data.sync?.workflow.env.method;

        // Bind the headers if present
        const headers = response.data?.sync?.workflow?.tests?.syncTest1?.steps?.[0]?.http?.headers;
        const asyncHeaders =
          response.data?.async?.workflow?.tests?.asyncTest1?.steps?.[0]?.http?.headers;
        if (headers) {
          const result = Object.entries(headers).map(([key, value]) => {
            if (key === 'Authorization' && value) {
              // Check for Bearer Auth
              if (value.includes('Bearer ')) {
                json.authType = 'bearerToken';
                setAuthType('bearerToken');
                // reverse the array after splitting to avoid optional chaining
                json.bearerToken = value.split('Bearer ').reverse()[0]; // get the token value
              }

              // Check for Basic Auth
              if (value.includes('Basic')) {
                json.authType = 'basicAuth';
                setAuthType('basicAuth');
                // reverse the array after splitting to avoid optional chaining
                const token = value.split('Basic ').reverse()[0]; // get the token value
                if (token) {
                  // Since it is a basic token, we need to decrypt it
                  const decryptedData = atob(token).split(':');
                  json.username = decryptedData?.[0];
                  json.password = decryptedData?.[1];
                }
              }
            } else {
              return { key, value };
            }
          });
          json.headerValues = result.filter((obj) => obj);
        }
        if (asyncHeaders) {
          const result = Object.entries(asyncHeaders).map(([key, value]) => {
            if (key === 'Authorization' && value) {
              // Check for Bearer Auth
              if (value.includes('Bearer ')) {
                json.asyncAuthType = 'bearerToken';
                setAsyncAuthType('bearerToken');
                // reverse the array after splitting to avoid optional chaining
                json.asyncBearerToken = value.split('Bearer ').reverse()[0]; // get the token value
              }

              // Check for Basic Auth
              if (value.includes('Basic ')) {
                json.asyncAuthType = 'basicAuth';
                setAsyncAuthType('basicAuth');
                // reverse the array after splitting to avoid optional chaining
                const token = value.split('Basic ').reverse()[0]; // get the token value
                if (token) {
                  // Since it is a basic token, we need to decrypt it
                  const decryptedData = atob(token).split(':');
                  json.asyncUsername = decryptedData?.[0];
                  json.asyncPassword = decryptedData?.[1];
                }
              }
            } else {
              return { key, value };
            }
          });
          json.asyncHeaderValues = result.filter((obj) => obj);
        }
        setResultData(response.data.sync?.result?.tests);
        console.log('response.data.sync?.result?.tests', response.data.sync?.result?.tests);
        response.data.sync?.result?.tests.forEach((step) => {
          const checks = step?.steps?.[0]?.checks?.jsonpath;
          const errorsArray1 = [];

          if (checks) {
            Object.entries(checks).forEach(([key, value]) => {
              if (value.passed === false) {
                const extractedMessage = value?.given
                  ? value.given.includes('<pre>')
                    ? value.given.match(/<pre>(.*?)<\/pre>/s)?.[1]?.trim()
                    : value.given
                  : 'Error message not found';

                // plainTextContent += `*Error for: ${key}*\n  - ${extractedMessage}\n`;
                errorsArray1.push({ [key]: extractedMessage });
              }
            });
          }
          setErrorsArray2((prev) => [...prev, ...errorsArray1]);
          console.log('ERROR ARRAY !', errorsArray1);
        });

        mapFormData(json, response.data.sync, API_TYPES.SYNC.toLowerCase());
      }
      if (response.data?.async && Object.keys(response?.data?.async).length > 0) {
        json.asyncAPIName = response.data?.async?.workflow?.name;
        json.asyncAPIEndPointURL = response.data?.async?.workflow?.env?.host;
        json.asyncAPIHttpMethod = response.data?.async?.workflow?.env?.method;
        setAsyncResultData(response.data?.async?.result?.tests);
        mapFormData(json, response.data?.async, API_TYPES.ASYNC.toLowerCase());
      }
      setFormData(json);
    } catch (error) {
      console.log('Error while fetching api detail for api: ', error);
    } finally {
      resetLoaders();
    }
  };

  const mapFormData = (json, data, type) => {
    const testKeyName = type.toUpperCase() === API_TYPES.SYNC ? 'tests' : 'asyncAPITests';
    json[testKeyName] = [];
    for (const [key, value] of Object.entries(data?.workflow?.tests)) {
      const testData = value?.steps[0];
      const paramStr = getParamStringFromURL(data.workflow.env.host, testData?.http?.url);
      json[testKeyName].push({
        name: testData?.name,
        requestBody: testData?.http?.json ? JSON.stringify(testData?.http?.json, null, 2) : '',
        responseBody: testData?.http?.check?.jsonpath
          ? JSON.stringify(unFlattenJSON(testData?.http?.check?.jsonpath), null, 2)
          : '',
        isBodyDynamic: false,
        showParamsField: paramStr ? true : false,
        paramsString: paramStr
      });
    }
    return json;
  };

  const handleAsyncMethodChange = (newMethod) => {
    setFormData((prevState) => ({
      ...prevState,
      asyncAPIHttpMethod: newMethod
    }));
  };

  const handleKeyValueBlurOrChange = (fields, index, values) => {
    const currentPair = values.headerValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ ...defaultHeaderObj });
    }
  };

  const handleKeyValueBlurOrChangeForAsync = (fields, index, values) => {
    const currentPair = values.asyncHeaderValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ ...defaultHeaderObj });
    }
  };
  const onSubmit = async (formData) => {
    console.log(formData);

    if (formData?.tests) {
      const isEmpty = formData.tests.findIndex((test) => test.name?.trim() === '' || !test?.name);
      if (isEmpty >= 0) {
        toast({
          id: 1,
          variant: 'destructive',
          title: 'Sync API',
          description: `Please add name for all test cases for API`
        });
        return;
      }
    }

    if (!isSyncAPI && formData?.asyncAPITests) {
      const isEmpty = formData.asyncAPITests.findIndex(
        (test) => test.name?.trim() === '' || !test?.name
      );
      if (isEmpty >= 0) {
        toast({
          id: 1,
          variant: 'destructive',
          title: 'Async API',
          description: `Please add name for all test cases for Async API`
        });
        return;
      }
    }

    const payloadBuilder = new JSONBuilder(formData, API_TYPES.SYNC.toLowerCase());
    let payload = payloadBuilder.createPayload();
    let apiId = APIId;
    let filePath = '';
    try {
      console.log('isSyncAPI>>>', isSyncAPI);
      payload = {
        id: apiId || '',
        sync: payload,
        groupId: '',
        // groupId: selectedGroupId === DEFAULT_USER_GROUP ? "" : typeof selectedGroupId === "object" ? "" : selectedGroupId,
        testType: isSyncAPI ? API_TYPES.SYNC.toLowerCase() : API_TYPES.ASYNC.toLowerCase()
      };
      setLoadingMessage('Running Tests...');
      setIsLoading(true);
      const response = await runTests(payload);
      setApiResponse(JSON.stringify(response.data?.data, null, 2));
      setResultData(response.data?.data?.result?.tests);
      apiId = response.data.id;
      setAPIId(apiId);
      if (response?.data?.filePath) {
        filePath = response.data.filePath;
        setReportFilePath(filePath);
        response.data?.data?.result?.tests.forEach((step) => {
          const checks = step?.steps?.[0]?.checks?.jsonpath;
          const errorsArray1 = [];

          if (checks) {
            Object.entries(checks).forEach(([key, value]) => {
              if (value.passed === false) {
                const extractedMessage = value?.given
                  ? value.given.includes('<pre>')
                    ? value.given.match(/<pre>(.*?)<\/pre>/s)?.[1]?.trim()
                    : value.given
                  : 'Error message not found';

                errorsArray1.push({ [key]: extractedMessage });
              }
            });
          }

          // Update the state with errors
          setErrorsArray2((prev) => [...prev, ...errorsArray1]);
        });
      }
    } catch (error) {
      console.log('Error: ', error);
      setApiResponse(`Error: ${error.message}`);
    } finally {
      resetLoaders();
      if (apiType === API_TYPES.ASYNC) {
        initAsyncAPICall(apiId, formData);
      }
    }
  };

  const generateReport = async (e) => {
    e.preventDefault();
    setLoadingMessage('Generating Report...');
    setIsLoading(true);
    try {
      const payload = {
        filePath: reportFilePath
      };
      const response = await getReportFilePath(payload);
      if (response?.data?.url) {
        window.open(response.data.url);
      }
    } catch (error) {
      console.log('Error while downloading the report: ', error);
    } finally {
      resetLoaders();
    }
  };

  const resetLoaders = () => {
    setLoadingMessage('');
    setIsLoading(false);
  };

  const initAsyncAPICall = async (apiId, formData) => {
    let asyncTimer = 5;
    // let timeInSeconds = asyncTimer / 1000
    const timer = getDataFromLocalStorage(LOCAL_STORAGE_KEYS.ASYNC_TIMER_INFO);
    if (timer) {
      const time = JSON.parse(timer);
      asyncTimer = Number(time.timer);

      // Correct use of isNaN to check if asyncTimer is not a number
      if (isNaN(asyncTimer)) {
        asyncTimer = 5; // Default value
      }

      // If needed, convert asyncTimer to seconds
      // timeInSeconds = asyncTimer / 1000;
    }

    const asyncFormData = {
      name: formData.asyncAPIName,
      url: formData.asyncAPIEndPointURL,
      headerValues: formData.asyncHeaderValues,
      httpMethod: formData.asyncAPIHttpMethod,
      authType: asyncAuthType,
      tests: formData.asyncAPITests,
      bearerToken: formData.asyncBearerToken,
      username: formData.asyncUsername,
      password: formData.asyncPassword
    };
    const payloadBuilder = new JSONBuilder(asyncFormData, API_TYPES.ASYNC.toLowerCase());
    let payload = payloadBuilder.createPayload();

    payload = {
      id: apiId,
      async: payload,
      groupId: '',
      // groupId: selectedGroupId === DEFAULT_USER_GROUP ? "" : typeof selectedGroupId === "object" ? "" : selectedGroupId,
      testType: isSyncAPI ? API_TYPES.SYNC.toLowerCase() : API_TYPES.ASYNC.toLowerCase()
    };

    try {
      const asyncResponse = await saveAsyncTest(apiId, payload);
    } catch (error) {
      console.log('Error: ', error);
      setApiResponse(`Error: ${error.message}`);
    }
    toast({
      variant: 'success',
      title: 'Async API',
      description: `Async API will trigger in ${asyncTimer} seconds`
    });
    setTimeout(async () => {
      try {
        let filePath = '';
        const response = await runTests(payload);
        setAsyncResultData(response?.data?.data?.result?.tests);
        filePath = response?.data?.filePath;
        setReportFilePath(filePath);
        toast({
          variant: 'success',
          title: 'Async API',
          description: `Test API ${formData.name} is completed with all async validation`
        });
      } catch (error) {
        console.log('Error: ', error);
        toast({
          id: 1,
          variant: 'destructive',
          title: 'Async API',
          description: `Async API failed`
        });
      }
    }, asyncTimer * 1000);
  };

  const addMoreTest = (fields, values) => {
    fields.push(deepClone(defaultTestObj));
  };

  const addMoreAsyncTest = (fields, values) => {
    fields.push(deepClone(defaultTestObj));
  };

  const removeTestCase = (index, fields, values) => {
    fields.remove(index);
  };

  const removeAsyncTestCase = (index, fields, values) => {
    fields.remove(index);
  };

  const handleCloseIsCurl = () => {
    setIsCurlOpen(!isCurlOpen);
    setCurlInput('');
  };

  const handleCloseIssueCreatedSuccessfullyDialog = () => {
    setIssueCreatedSuccessfullyDialog(!issueCreatedSuccessfullyDialog);
  };

  const [curlForAsync, setCurlForAsync] = useState(false);
  const [curlInput, setCurlInput] = useState('');
  const [parsedData, setParsedData] = useState(null);

  const handleParseCurl = (curlInput) => {
    try {
      const parseCurlCommand = (curlCommand) => {
        // Extract URL
        const urlRegex = /curl --location '(.*?)'/;
        const urlMatch = curlCommand.match(urlRegex);
        const url = urlMatch ? urlMatch[1] : null;

        // Determine HTTP Method
        let method = 'GET';
        if (curlCommand.includes('--form') || curlCommand.includes('--data')) {
          method = 'POST';
        }

        // Extract Headers
        const headerRegex = /--header '(.*?): (.*?)'/g;
        const headers = {};
        let match;
        while ((match = headerRegex.exec(curlCommand)) !== null) {
          headers[match[1]] = match[2];
        }

        // Extract Form Data
        const formRegex = /--form '(.*?)=(.*?)'/g;
        const formData = {};
        while ((match = formRegex.exec(curlCommand)) !== null) {
          const key = match[1];
          const value = match[2].startsWith('@') ? `File: ${match[2].slice(1)}` : match[2];
          formData[key] = value;
        }

        return { url, method, headers, formData };
      };

      const ArrayOfAuthType = ['Bearer', 'Basic'];

      const parsedData = parseCurlCommand(curlInput);
      console.log('Parsed Data:', parsedData);
      if (!curlForAsync) {
        let authType;

        // Check if authorization header exists
        if (parsedData.headers && parsedData.headers.authorization) {
          const authorizationHeader = parsedData.headers.authorization;

          // Check if the authorization type matches any in the ArrayOfAuthType
          const matchedAuthType = ArrayOfAuthType.find((auth) =>
            authorizationHeader.includes(auth)
          );

          if (!matchedAuthType) {
            authType = 'noAuth'; // If no match found, set authType to "no auth"
          } else {
            if (matchedAuthType === 'Basic') {
              authType = 'basicAuth';
            }
            if (matchedAuthType === 'Bearer') authType = 'bearerToken'; // If a match is found, set it to the matched type
          }
        } else {
          authType = 'noAuth'; // No Authorization header found
        }

        console.log('Authorization Type:', authType);
        setFormData((prevState) => ({
          ...prevState,
          url: parsedData.url,
          httpMethod: parsedData.method.toLocaleLowerCase(),
          headerValues: Object.entries(parsedData.headers).map(([key, value]) => ({ key, value })),
          asyncAuthType: authType
        }));
        setAuthType(authType);
        setIsCurlOpen(!isCurlOpen);
        setCurlInput('');
        return parsedData; // Use this for further processing or displaying
      }
      if (curlForAsync) {
        let authType;

        // Check if authorization header exists
        if (parsedData.headers && parsedData.headers.authorization) {
          const authorizationHeader = parsedData.headers.authorization;

          // Check if the authorization type matches any in the ArrayOfAuthType
          const matchedAuthType = ArrayOfAuthType.find((auth) =>
            authorizationHeader.includes(auth)
          );

          if (!matchedAuthType) {
            authType = 'noAuth'; // If no match found, set authType to "no auth"
          } else {
            if (matchedAuthType === 'Basic') {
              authType = 'basicAuth';
            }
            if (matchedAuthType === 'Bearer') authType = 'bearerToken'; // If a match is found, set it to the matched type
          }
        } else {
          authType = 'noAuth'; // No Authorization header found
        }
        setAsyncAuthType(authType);
        setFormData((prevState) => ({
          ...prevState,
          asyncAPIEndPointURL: parsedData.url,
          asyncAPIHttpMethod: parsedData.method.toLocaleLowerCase(),
          asyncHeaderValues: Object.entries(parsedData.headers).map(([key, value]) => ({
            key,
            value
          })),
          asyncAuthType: authType
        }));

        setIsCurlOpen(!isCurlOpen);
        setCurlForAsync(false);
        setCurlInput('');
        return parsedData; // Use this for further processing or displaying
      }
    } catch (error) {
      console.error('Error parsing curl:', error);
      return null;
    }
  };

  // upload file in jira file function after create issue in jira
  const [copyMessage, setCopyMessage] = useState();
  const handleUploadFileInJira = async (formData) => {
    try {
      if (formData) {
        const response = await uploadFileJira(formData);
        console.log('Repsonse for jira upload file', response);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('Error in jira upload file api', error);
    }
  };

  // create issue in jira function

  const handleCreateIssue = async () => {
    setIsLoading(true);
    setLoadingMessage('Creating Issue...');
    console.log('Result Data', resultData);

    let plainTextContent = '';
    const errorsArray = []; // Initialize an array to store errors
    let comment = '';
    resultData.forEach((step) => {
      const checks = step?.steps?.[0]?.checks?.jsonpath;

      if (checks) {
        Object.entries(checks).forEach(([key, value]) => {
          if (value.passed === false) {
            const extractedMessage = value?.given
              ? value.given.includes('<pre>')
                ? value.given.match(/<pre>(.*?)<\/pre>/s)?.[1]?.trim()
                : value.given
              : 'Error message not found';

            // plainTextContent += `*Error for: ${key}*\n  - ${extractedMessage}\n`;
            errorsArray.push({ [key]: extractedMessage });
          }
        });
      }
    });

    if (errorsArray.length === 0) {
      setISCreateIssue(false);
      setIsLoading(false);
      return;
    }

    errorsArray.forEach((error, index) => {
      const key = Object.keys(error)[0];
      const value = Object.values(error)[0];
      plainTextContent += `*${index + 1}. Error for: ${key}*\n  - ${value}\n\n`;
    });
    console.log('Generated HTML Content:', plainTextContent);

    // Prepare the payload for issue creation
    const payload = {
      issue_name: `${formData?.name || 'Unnamed'}`,
      description: plainTextContent // Attach the error report HTML
    };

    try {
      // Call the createIssue API
      const response = await createIssue(payload);
      console.log('Response for handleCreateIssue', response);
      if (response.data.data) {
        setJiraKey(response?.data?.data?.key);
        const payload = {
          jira_key: response?.data?.data?.key,
          // filePath:"_Ab7XJMBY_SNpKPJoEFt/_Ab7XJMBY_SNpKPJoEFt_2024-11-24T07-03-37-097Z.pdf"
          filePath: reportFilePath
        };
        handleUploadFileInJira(payload);

        let i = 0;
        for (const error of errorsArray) {
          console.log('ERRR', error);
          try {
            const key = Object.keys(error)[0]; // Extract the key (e.g., $.user.email)
            const value = Object.values(error)[0]; // Extract the value (e.g., Cannot GET ...)

            // Create the formatted key-value string
            const formattedError = `"${key}": "${value}"`;

            const solutionPayload = {
              user_id: user?.user_id,
              error: formattedError // Send the formatted key-value string
            };

            const solutionResponse = await getSolutionForApiAutomation(solutionPayload);
            console.log('Solution for error:', solutionResponse);
            // comment += `Error for: ${Object.keys(error)[0]}\n  - ${solutionResponse.data}\n`;
            comment += `*${i + 1}. Solution for: ${Object.keys(error)[0]}*\n  - ${solutionResponse.data}\n\n`;
            i++;
          } catch (solutionError) {
            console.error('Error fetching solution for:', error, solutionError);
          }
        }
        console.log(comment);
        const commentPayload = {
          issue_key: response?.data?.data?.key,
          comment: comment
        };
        const commentResponse = await addCommentInJira(commentPayload);
        console.log('response for add comment in jira', commentResponse);

        if (commentResponse.data.data.body) {
          setIssueCreatedSuccessfullyDialog(true);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error in handleCreateIssue', error);
    }
  };

  useEffect(() => {
    if (isCreateIssue) {
      handleCreateIssue();
    }
  }, [isCreateIssue]);

  // UI Rendering
  return (
    <main className="gap-4 p-4 mt-8 sm:py-0 md:gap-8">
      {isLoading && <Loader loadingMessage={loadingMessage} />}
      <div className="mb-4 flex justify-between">
        <BackButton routeName={backButtonRouteName} />
        <div className="flex justify-end items-center">
          {/* <Button
            onClick={() => {}}
            size="lg"
            variant="default"
            className="h-8 gap-1 text-sm"
          >
            <Save className="h-3.5 w-3.5" stroke="white" />
            <span className="sr-only sm:not-sr-only text-white">Save Test</span>
          </Button> */}
          {apiType === API_TYPES.SYNC && !testId && (
            <Button
              type="button"
              onClick={() => {
                setIsCurlOpen(!isCurlOpen);
              }}
              size="lg"
              variant="green"
              className="h-8 gap-1 text-sm ml-2">
              <span className="sr-only sm:not-sr-only text-white">Import From Curl</span>
            </Button>
          )}
          <Dialog
            open={isCurlOpen}
            onClose={handleCloseIsCurl}
            aria-describedby="alert-dialog-description"
            fullWidth={'lg'}
            maxWidth={'lg'} // Adjust the max width to large (lg)
          >
            <DialogTitle
              sx={{
                fontSize: '1.25rem',
                fontWeight: '600',
                backgroundColor: '#161A27',
                color: 'white'
              }}>
              Paste Curl Command
            </DialogTitle>
            <DialogContent sx={{ padding: '16px', backgroundColor: '#161A27' }}>
              <TextArea
                fullWidth
                className=" bg-transparent w-full p-1 rounded-md border px-4 border-gray-600 min-h-36 text-white  placeholder:text-white "
                id="curl"
                label="Curl Command"
                placeholder="Paste Curl Here"
                value={curlInput}
                onChange={(e) => setCurlInput(e.target.value)}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: '#bbb' // Light color for input label
                  },
                  '& .MuiInputBase-root': {
                    color: 'white' // Text color inside input field
                  },
                  '& .MuiInputBase-input': {
                    color: '#fff' // Ensure input text is white
                  }
                }}
              />
              <div className="flex w-full justify-end gap-8 my-8 ">
                <button
                  onClick={handleCloseIsCurl}
                  className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-[#dc3545] text-primary-foreground shadow hover:bg-[#c82333] rounded-md px-8 h-8 gap-1 text-sm">
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => handleParseCurl(curlInput)}
                  className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-[#9747FF] text-primary-foreground shadow hover:bg-[#9747FF] rounded-md px-8 h-8 gap-1 text-sm">
                  Paste
                </button>
              </div>
            </DialogContent>
          </Dialog>

          {apiType === API_TYPES.ASYNC && <SettingsDropdown />}

          {/* <Button type="button" onClick={() => {}} size="lg" variant="green" className="h-8 gap-1 text-sm ml-2">
						<Download className="h-3.5 w-3.5" stroke="white" />
						<span className="sr-only sm:not-sr-only text-white">Report</span>
					</Button> */}

          {reportFilePath && errorsArray2.length > 0 && (
            <div className="text-white h-10 w-60 text-base rounded-md justify-center  border-2  flex items-center gap-2 font-bold  ">
              <span>Create Issue</span>
              <Switch
                id="create-issue"
                className="bg-background "
                value={isCreateIssue}
                onCheckedChange={() => setISCreateIssue(!isCreateIssue)}
              />
            </div>
          )}

          <Dialog
            open={issueCreatedSuccessfullyDialog}
            onClose={handleCloseIssueCreatedSuccessfullyDialog}
            aria-describedby="alert-dialog-description"
            fullWidth={'sm'}
            maxWidth={'sm'} // Adjust the max width to small (sm)
          >
            <DialogTitle
              sx={{
                fontSize: '1.25rem',
                fontWeight: '600',
                backgroundColor: '#161A27',
                color: 'white'
              }}>
              Created Issue Successfully
            </DialogTitle>
            <DialogContent
              sx={{
                padding: '16px',
                backgroundColor: '#161A27',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}>
              <div className="flex justify-between items-center gap-4">
                <span className="whitespace-nowrap font-medium text-sm text-white">JIRA KEY</span>
                <div className="flex items-center gap-2 w-full">
                  <input
                    className="bg-transparent p-1 rounded-md border px-4 border-gray-600 min-h-9 text-white max-h-24 placeholder:text-white flex-grow"
                    id="jiraKey"
                    value={jiraKey}
                    disabled
                  />
                  <button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(jiraKey);
                      setCopyMessage('Copied Successfully!');
                      setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
                    }}
                    className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-[#9747FF] text-primary-foreground shadow hover:bg-[#9747FF] rounded-md px-4 h-8 text-sm">
                    Copy
                  </button>
                </div>
              </div>

              {copyMessage && (
                <p className="text-sm text-green-500 font-medium text-center">{copyMessage}</p>
              )}

              <div className="flex justify-end gap-4 mt-4">
                <button
                  type="button"
                  onClick={handleCloseIssueCreatedSuccessfullyDialog}
                  className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-[#9747FF] text-primary-foreground shadow hover:bg-[#9747FF] rounded-md px-6 h-8 text-sm">
                  Done
                </button>
              </div>
            </DialogContent>
          </Dialog>

          {reportFilePath && <ReportPublishDropdown filePath={reportFilePath} testId={testId} />}

          {reportFilePath && (
            <Button
              onClick={(e) => generateReport(e)}
              size="lg"
              variant="green"
              className="h-8 gap-1 text-sm ml-2">
              <Download className="h-3.5 w-3.5" stroke="white" />
              <span className="sr-only sm:not-sr-only text-white">Report</span>
            </Button>
          )}
        </div>
      </div>
      <hr className="mb-4" />
      <div>
        <Form
          onSubmit={onSubmit}
          initialValues={formData}
          keepDirtyOnReinitialize
          mutators={{
            ...arrayMutators
          }}
          render={({ handleSubmit, values }) => (
            <React.Fragment>
              <form onSubmit={handleSubmit}>
                <SplitPane
                  paneClassName="overflow-auto"
                  defaultSize={screenWidth / paneSize}
                  minSize={screenWidth / 4}
                  maxSize={screenWidth / 2 + screenWidth / 4}
                  resizerStyle={{
                    backgroundColor: '#fff',
                    width: '1px',
                    cursor: 'col-resize',
                    margin: '2px'
                  }}>
                  <Pane className="">
                    {apiType === API_TYPES.ASYNC && !testId && (
                      <div className="flex w-full justify-end pr-5">
                        <Button
                          type="button"
                          onClick={() => {
                            setIsCurlOpen(!isCurlOpen);
                          }}
                          size="lg"
                          variant="green"
                          className="h-8 gap-1 text-sm ml-2">
                          <span className="sr-only sm:not-sr-only text-white  ">
                            Import From Curl
                          </span>
                        </Button>
                      </div>
                    )}
                    <div className="mx-2">
                      <div className="grid grid-cols-12 gap-4 mb-4">
                        <div className={`col-span-${isSyncAPI ? 3 : 12} font-semibold`}>
                          <Label htmlFor="name" className={`mb-2 text-sm block text-left`}>
                            API Name *
                          </Label>
                          <Field
                            className="bg-[#161A27]"
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Enter api name like “Load Test 1”, etc. "
                            validate={composeValidators(required)}>
                            {renderField}
                          </Field>
                        </div>
                        <div className={`col-span-${isSyncAPI ? 2 : 12} font-semibold`}>
                          <Label htmlFor="httpMethod" className={`mb-2 text-sm block text-left`}>
                            HTTP Method *
                          </Label>
                          <Field
                            name="httpMethod"
                            component="select"
                            onChange={(event) => handleMethodChange(event.target.value)}
                            className="bg-[#161A27] w-full block p-1 rounded-md border border-gray-600 h-9">
                            <option value="">Select Method</option>
                            {HTTP_METHODS.map((methodObj) => (
                              <option key={methodObj.method} value={methodObj.method}>
                                {methodObj.label}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className={`col-span-${isSyncAPI ? 7 : 12} font-semibold`}>
                          <Label htmlFor="url" className={`mb-2 text-sm block text-left`}>
                            URL *
                          </Label>
                          <Field
                            className="bg-[#161A27]"
                            id="url"
                            type="text"
                            name="url"
                            placeholder="Enter rest api end point"
                            validate={composeValidators(required)}>
                            {renderField}
                          </Field>
                        </div>
                      </div>

                      <div className="flex items-center space-x-4 mb-4">
                        <Label htmlFor="headers" className="text-lg font-bold">
                          Authorization Type
                        </Label>
                        <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                      </div>
                      <div className="mb-4">
                        <div className="grid grid-cols-12 gap-4 mb-4">
                          <div className={`col-span-${isSyncAPI ? 3 : 12} font-semibold`}>
                            <Field name="authType" component="select" value={authType}>
                              {({ input }) => (
                                <div className="w-full flex flex-col mb-2">
                                  <select
                                    {...input}
                                    value={authType} // Set the value of the select element to authType state
                                    onChange={(e) => {
                                      input.onChange(e);
                                      setAuthType(e.target.value); // Update authType state when selection changes
                                    }}
                                    className="border border-[#444444] bg-[#161A27] rounded-sm p-1 h-9">
                                    <option key="select" value="" disabled>
                                      Select authorization type
                                    </option>
                                    {AUTH_TYPES.map((type, index) => (
                                      <option key={index} value={type.value}>
                                        {type.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            </Field>

                            <p className="text-left text-base text-[#A1A1AA] font-normal manrope-fontCss">
                              {authType === 'noAuth' && (
                                <>
                                  The authorization header will be automatically generated when you
                                  send the request. Learn more about{' '}
                                  <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                                  authorization.
                                </>
                              )}
                              {authType === 'bearerToken' && (
                                <>
                                  Bearer Token requires you to provide an access token for
                                  authorization. Learn more about{' '}
                                  <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                                  authorization.
                                </>
                              )}
                              {authType === 'basicAuth' && (
                                <>
                                  Basic Authentication requires a username and password. Learn more
                                  about{' '}
                                  <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                                  authorization.
                                </>
                              )}
                              {authType === 'jwtbearer' && (
                                <div className="flex flex-col space-y-4 w-full">
                                  <div className="">
                                    Basic Authentication requires a username and password. Learn
                                    more about{' '}
                                    <span className="text-[#9747FF] manrope-fontCss">
                                      JWT Bearer Token
                                    </span>{' '}
                                    authorization.
                                  </div>
                                  <div className="flex flex-row space-x-2 w-full">
                                    <p className="w-[65%]">Add JWT Token to </p>

                                    <span className="-mt-1">
                                      <Field name="algorithm" component="select">
                                        {({ input }) => (
                                          <select
                                            {...input}
                                            className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                            <option value="" disabled></option>
                                            {ALGORITHM_OPTIONS.map((option) => (
                                              <option key={option.value} value={option.value}>
                                                {option.label}
                                              </option>
                                            ))}
                                          </select>
                                        )}
                                      </Field>
                                    </span>
                                  </div>
                                </div>
                              )}
                              {authType === 'digitalauth' && (
                                <>
                                  <div className="text-base flex flex-col space-y-4">
                                    <div>
                                      Basic Authentication requires a username and password. Learn
                                      more about{' '}
                                      <span className="text-[#9747FF] manrope-fontCss">
                                        JWT Bearer
                                      </span>{' '}
                                      authorization.
                                    </div>
                                    <div>
                                      By default, Scale Secure will extract values from the received
                                      response, add it to the request, and retry it. Do you want to
                                      disable this?
                                    </div>

                                    <div className="flex items-center space-x-4">
                                      <Checkbox />
                                      <p>Yes, disable retrying the request</p>
                                    </div>
                                  </div>
                                </>
                              )}

                              {authType === 'oauth1' && (
                                <div className="flex flex-col space-y-4">
                                  <div>
                                    Basic Authentication requires a username and password. Learn
                                    more about{' '}
                                    <span className="text-[#9747FF] manrope-fontCss">
                                      OAuth 1.0
                                    </span>{' '}
                                    authorization.
                                  </div>
                                  <div className="flex flex-row space-x-2">
                                    <p className="w-[65%]">Add Auth Data to</p>

                                    <span className="-mt-1 flex flex-col w-[55%] gap-2">
                                      <Field name="algorithm" component="select">
                                        {({ input }) => (
                                          <select
                                            {...input}
                                            className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                            <option value="" disabled></option>
                                            {ALGORITHM_OPTIONS.map((option) => (
                                              <option key={option.value} value={option.value}>
                                                {option.label}
                                              </option>
                                            ))}
                                          </select>
                                        )}
                                      </Field>
                                      <p>
                                        Postman will automatically choose between body and URL based
                                        on the request method.
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              )}
                              {!authType && (
                                <>
                                  The authorization header will be automatically generated when you
                                  send the request. Select an authorization type to see more
                                  details.
                                </>
                              )}
                            </p>
                          </div>

                          <div
                            className={`flex flex-col col-span-${isSyncAPI ? 9 : 12} font-semibold`}>
                            <Card className="w-full h-auto p-3 border border-[#747474]">
                              {authType === LOAD_TEST_AUTH_TYPE[0].value && (
                                <div className="flex flex-col gap-4 items-center">
                                  <p className="text-sm text-[#A1A1AA]">
                                    This request does not use any authorization. Learn more about
                                    authorization.
                                  </p>
                                </div>
                              )}
                              {authType === LOAD_TEST_AUTH_TYPE[1].value && (
                                <div className="flex flex-col gap-4">
                                  <div className="grid grid-cols-1 gap-4">
                                    <div className="grid gap-2">
                                      <div className="relative">
                                        <Field
                                          id="username"
                                          type="text"
                                          name="username"
                                          validate={composeValidators(required)}>
                                          {({ input }) => (
                                            <>
                                              <input
                                                {...input}
                                                type="text"
                                                className="block w-full px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                                placeholder=" "
                                                value={values.username}

                                                // onChange={(e) =>
                                                //   setValues({ ...values, username: e.target.value })
                                                // }
                                              />
                                              <label
                                                htmlFor="username"
                                                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                                Username *
                                              </label>
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="grid gap-2">
                                    <div className="relative">
                                      <Field
                                        id="password"
                                        type="password"
                                        name="password"
                                        validate={composeValidators(required)}>
                                        {({ input }) => (
                                          <>
                                            <input
                                              {...input}
                                              type="password"
                                              className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                              placeholder=" "
                                              value={values.password}
                                              // onChange={(e) =>
                                              //   setValues({ ...values, password: e.target.value })
                                              // }
                                            />
                                            <label
                                              htmlFor="password"
                                              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                              Password *
                                            </label>
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {authType === LOAD_TEST_AUTH_TYPE[2].value && (
                                <div className="grid grid-cols-1 pt-2 gap-2">
                                  <div className="grid gap-4 ">
                                    <div className="relative ">
                                      <Field
                                        id="bearerToken"
                                        type="textarea"
                                        name="bearerToken"
                                        validate={composeValidators(required)}>
                                        {({ input }) => (
                                          <>
                                            <textarea
                                              {...input}
                                              className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                              placeholder=" "
                                              value={values.bearerToken}
                                              // onChange={(e) =>
                                              //   setValues({ ...values, bearerToken: e.target.value })
                                              // }
                                            />
                                            <label
                                              htmlFor="bearerToken"
                                              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                              Bearer Token *
                                            </label>
                                          </>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Card>
                          </div>
                        </div>
                      </div>

                      <Headers
                        values={values}
                        fieldsName="headerValues"
                        handleKeyValueBlurOrChange={handleKeyValueBlurOrChange}
                      />

                      <TestCase
                        key="tests"
                        apiName={values.name}
                        fieldName="tests"
                        values={values}
                        isSyncAPI={isSyncAPI}
                        resultData={resultData}
                        addMoreTest={addMoreTest}
                        removeTestCase={removeTestCase}
                        composeValidators={composeValidators}
                        handleKeyValueBlurOrChange={handleKeyValueBlurOrChange}
                      />
                    </div>
                  </Pane>

                  {apiType === API_TYPES.ASYNC && (
                    <>
                      <Pane className="">
                        <div className="flex w-full justify-end pr-5">
                          {!testId && (
                            <Button
                              type="button"
                              onClick={() => {
                                setIsCurlOpen(!isCurlOpen);

                                setCurlForAsync(true);
                                return;
                              }}
                              size="lg"
                              variant="green"
                              className="h-8 gap-1 text-sm ml-2">
                              <span className="sr-only sm:not-sr-only text-white  ">
                                Import From Curl
                              </span>
                            </Button>
                          )}
                        </div>
                        <div className="mx-2">
                          <div className="grid grid-cols-12 gap-4 mb-4">
                            <div className="col-span-12 font-semibold">
                              <Label
                                htmlFor="asyncAPIName"
                                className={`mb-2 text-sm block text-left`}>
                                API Name *
                              </Label>
                              <Field
                                className="bg-[#161A27]"
                                id="asyncAPIName"
                                type="text"
                                name="asyncAPIName"
                                placeholder="Enter api name like “Load Test 1”, etc. "
                                validate={composeValidators(required)}>
                                {renderField}
                              </Field>
                            </div>
                            <div className="col-span-12 font-semibold">
                              <Label
                                htmlFor="asyncAPIHttpMethod"
                                className={`mb-2 text-sm block text-left`}>
                                HTTP Method *
                              </Label>
                              <Field
                                name="asyncAPIHttpMethod"
                                component="select"
                                onChange={(event) => handleAsyncMethodChange(event.target.value)}
                                className="bg-[#161A27] w-full block p-1 rounded-md border border-gray-600 h-9">
                                <option value="">Select Method</option>
                                {HTTP_METHODS.map((methodObj) => (
                                  <option key={methodObj.method} value={methodObj.method}>
                                    {methodObj.label}
                                  </option>
                                ))}
                              </Field>
                            </div>
                            <div className="col-span-12 font-semibold">
                              <Label
                                htmlFor="asyncAPIEndPointURL"
                                className={`mb-2 text-sm block text-left`}>
                                URL *
                              </Label>
                              <Field
                                className="bg-[#161A27]"
                                id="asyncAPIEndPointURL"
                                type="text"
                                name="asyncAPIEndPointURL"
                                placeholder="Enter rest api end point"
                                validate={composeValidators(required)}>
                                {renderField}
                              </Field>
                            </div>
                          </div>

                          <div className="flex items-center space-x-4 mb-4">
                            <Label htmlFor="headers" className="text-lg font-bold">
                              Authorization Type
                            </Label>
                            <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                          </div>
                          <div className="mb-4">
                            <div className="grid grid-cols-12 gap-4 mb-4">
                              <div className="col-span-12 font-semibold">
                                <Field
                                  name="asyncAuthType"
                                  component="select"
                                  value={asyncAuthType}>
                                  {({ input }) => (
                                    <div className="w-full flex flex-col mb-2">
                                      <select
                                        {...input}
                                        value={asyncAuthType} // Set the value of the select element to authType state
                                        onChange={(e) => {
                                          input.onChange(e);
                                          setAsyncAuthType(e.target.value); // Update authType state when selection changes
                                        }}
                                        className="border border-[#444444] bg-[#161A27] rounded-sm p-1 h-9">
                                        <option key="select" value="" disabled>
                                          Select authorization type
                                        </option>
                                        {AUTH_TYPES.map((type, index) => (
                                          <option key={index} value={type.value}>
                                            {type.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  )}
                                </Field>

                                <p className="text-left text-base text-[#A1A1AA] font-normal manrope-fontCss">
                                  {authType === 'noAuth' && (
                                    <>
                                      The authorization header will be automatically generated when
                                      you send the request. Learn more about{' '}
                                      <span className="text-[#9747FF] manrope-fontCss">
                                        API Key
                                      </span>{' '}
                                      authorization.
                                    </>
                                  )}
                                  {authType === 'bearerToken' && (
                                    <>
                                      Bearer Token requires you to provide an access token for
                                      authorization. Learn more about{' '}
                                      <span className="text-[#9747FF] manrope-fontCss">
                                        API Key
                                      </span>{' '}
                                      authorization.
                                    </>
                                  )}
                                  {authType === 'basicAuth' && (
                                    <>
                                      Basic Authentication requires a username and password. Learn
                                      more about{' '}
                                      <span className="text-[#9747FF] manrope-fontCss">
                                        API Key
                                      </span>{' '}
                                      authorization.
                                    </>
                                  )}
                                  {authType === 'jwtbearer' && (
                                    <div className="flex flex-col space-y-4 w-full">
                                      <div className="">
                                        Basic Authentication requires a username and password. Learn
                                        more about{' '}
                                        <span className="text-[#9747FF] manrope-fontCss">
                                          JWT Bearer Token
                                        </span>{' '}
                                        authorization.
                                      </div>
                                      <div className="flex flex-row space-x-2 w-full">
                                        <p className="w-[65%]">Add JWT Token to </p>

                                        <span className="-mt-1">
                                          <Field name="algorithm" component="select">
                                            {({ input }) => (
                                              <select
                                                {...input}
                                                className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                                <option value="" disabled></option>
                                                {ALGORITHM_OPTIONS.map((option) => (
                                                  <option key={option.value} value={option.value}>
                                                    {option.label}
                                                  </option>
                                                ))}
                                              </select>
                                            )}
                                          </Field>
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {authType === 'digitalauth' && (
                                    <>
                                      <div className="text-base flex flex-col space-y-4">
                                        <div>
                                          Basic Authentication requires a username and password.
                                          Learn more about{' '}
                                          <span className="text-[#9747FF] manrope-fontCss">
                                            JWT Bearer
                                          </span>{' '}
                                          authorization.
                                        </div>
                                        <div>
                                          By default, Scale Secure will extract values from the
                                          received response, add it to the request, and retry it. Do
                                          you want to disable this?
                                        </div>

                                        <div className="flex items-center space-x-4">
                                          <Checkbox />
                                          <p>Yes, disable retrying the request</p>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {authType === 'oauth1' && (
                                    <div className="flex flex-col space-y-4">
                                      <div>
                                        Basic Authentication requires a username and password. Learn
                                        more about{' '}
                                        <span className="text-[#9747FF] manrope-fontCss">
                                          OAuth 1.0
                                        </span>{' '}
                                        authorization.
                                      </div>
                                      <div className="flex flex-row space-x-2">
                                        <p className="w-[65%]">Add Auth Data to</p>

                                        <span className="-mt-1 flex flex-col w-[55%] gap-2">
                                          <Field name="algorithm" component="select">
                                            {({ input }) => (
                                              <select
                                                {...input}
                                                className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                                <option value="" disabled></option>
                                                {ALGORITHM_OPTIONS.map((option) => (
                                                  <option key={option.value} value={option.value}>
                                                    {option.label}
                                                  </option>
                                                ))}
                                              </select>
                                            )}
                                          </Field>
                                          <p>
                                            Postman will automatically choose between body and URL
                                            based on the request method.
                                          </p>
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {!authType && (
                                    <>
                                      The authorization header will be automatically generated when
                                      you send the request. Select an authorization type to see more
                                      details.
                                    </>
                                  )}
                                </p>
                              </div>

                              <div className="flex flex-col col-span-12 font-semibold">
                                <Card className="w-full h-auto p-3 border border-[#747474]">
                                  {asyncAuthType === LOAD_TEST_AUTH_TYPE[0].value && (
                                    <div className="flex flex-col gap-4 items-center">
                                      <p className="text-sm text-[#A1A1AA]">
                                        This request does not use any authorization. Learn more
                                        about authorization.
                                      </p>
                                    </div>
                                  )}
                                  {asyncAuthType === LOAD_TEST_AUTH_TYPE[1].value && (
                                    <div className="flex flex-col gap-4">
                                      <div className="grid grid-cols-1 gap-4">
                                        <div className="grid gap-2">
                                          <div className="relative">
                                            <Field
                                              id="asyncUsername"
                                              type="text"
                                              name="asyncUsername"
                                              validate={composeValidators(required)}>
                                              {({ input }) => (
                                                <>
                                                  <input
                                                    {...input}
                                                    type="text"
                                                    className="block w-full px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                                    placeholder=" "
                                                    value={values.asyncUsername}

                                                    // onChange={(e) =>
                                                    //   setValues({ ...values, asyncUsername: e.target.value })
                                                    // }
                                                  />
                                                  <label
                                                    htmlFor="asyncUsername"
                                                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                                    Username *
                                                  </label>
                                                </>
                                              )}
                                            </Field>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="grid gap-2">
                                        <div className="relative">
                                          <Field
                                            id="asyncPassword"
                                            type="asyncPassword"
                                            name="asyncPassword"
                                            validate={composeValidators(required)}>
                                            {({ input }) => (
                                              <>
                                                <input
                                                  {...input}
                                                  type="asyncPassword"
                                                  className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                                  placeholder=" "
                                                  value={values.asyncPassword}
                                                  // onChange={(e) =>
                                                  //   setValues({ ...values, asyncPassword: e.target.value })
                                                  // }
                                                />
                                                <label
                                                  htmlFor="asyncPassword"
                                                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                                  Password *
                                                </label>
                                              </>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {asyncAuthType === LOAD_TEST_AUTH_TYPE[2].value && (
                                    <div className="grid grid-cols-1 pt-2 gap-2">
                                      <div className="grid gap-4 ">
                                        <div className="relative ">
                                          <Field
                                            id="asyncBearerToken"
                                            type="textarea"
                                            name="asyncBearerToken"
                                            validate={composeValidators(required)}>
                                            {({ input }) => (
                                              <>
                                                <textarea
                                                  {...input}
                                                  className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                                  placeholder=" "
                                                  value={values.asyncBearerToken}
                                                  // onChange={(e) =>
                                                  //   setValues({ ...values, asyncBearerToken: e.target.value })
                                                  // }
                                                />
                                                <label
                                                  htmlFor="asyncBearerToken"
                                                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                                  Bearer Token *
                                                </label>
                                              </>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Card>
                              </div>
                            </div>
                          </div>

                          <Headers
                            values={values}
                            fieldsName="asyncHeaderValues"
                            handleKeyValueBlurOrChange={handleKeyValueBlurOrChangeForAsync}
                          />

                          <TestCase
                            key="asyncAPITests"
                            apiName={values.asyncAPIName}
                            fieldName="asyncAPITests"
                            values={values}
                            isSyncAPI={isSyncAPI}
                            resultData={asyncResultData}
                            addMoreTest={addMoreAsyncTest}
                            removeTestCase={removeAsyncTestCase}
                            composeValidators={composeValidators}
                            handleKeyValueBlurOrChange={handleKeyValueBlurOrChange}
                          />
                        </div>
                      </Pane>
                    </>
                  )}
                </SplitPane>
              </form>
            </React.Fragment>
          )}
        />
      </div>
    </main>
  );
};

export default TestForm;



