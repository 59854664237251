// Library imports
import React, { useState } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Trash2 } from "lucide-react";

// Component imports
import { Button } from "../../components/Button";
import { Label } from "../../components/form/Label";
import { Input } from "../../components/form/Input";

const Headers = (props) => {

  const { values, handleKeyValueBlurOrChange, fieldsName } = props
  return (
    <React.Fragment>
      <div className="flex items-center space-x-4 mb-4">
        <Label htmlFor="headers" className="text-lg font-bold">
          Headers
        </Label>
        <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
      </div>
      <div className="mb-4">
        <FieldArray name={fieldsName}>
          {({ fields }) => (
            <>
              {fields.map((name, index) => (
                <div
                  key={index}
                  className="grid grid-cols-12 gap-4 mb-4 items-center"
                >
                  <div className="col-span-5 font-semibold">
                    {index === 0 && (
                      <label
                        className={`mb-2 text-sm block text-left`}
                      >
                        Key
                      </label>
                    )}
                    <Field
                      name={`${name}.key`}
                      render={({ input }) => (
                        <Input
                          {...input}
                          placeholder="Key"
                          className="border p-2 rounded bg-[#161A27]"
                          onBlur={() =>
                            handleKeyValueBlurOrChange(
                              fields,
                              index,
                              values
                            )
                          }
                          onChange={(e) => {
                            input.onChange(e);
                            handleKeyValueBlurOrChange(
                              fields,
                              index,
                              values
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-7 font-semibold">
                    {index === 0 && (
                      <label
                        className={`mb-2 text-sm block text-left`}
                      >
                        Value
                      </label>
                    )}
                    <div className="flex items-center">
                      <Field
                        name={`${name}.value`}
                        render={({ input }) => (
                          <Input
                            {...input}
                            placeholder="Value"
                            className="border p-2 rounded bg-[#161A27]"
                            onBlur={() =>
                              handleKeyValueBlurOrChange(
                                fields,
                                index,
                                values
                              )
                            }
                            onChange={(e) => {
                              input.onChange(e);
                              handleKeyValueBlurOrChange(
                                fields,
                                index,
                                values
                              );
                            }}
                          />
                        )}
                      />
                      {fields.length > 1 && (
                        <div className="col-span-2 font-semibold">
                          <Trash2
                            color="red"
                            type="button"
                            onClick={() => fields.remove(index)}
                            className="focus:outline-none cursor-pointer ml-4"
                            size={18}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </FieldArray>
      </div>
    </React.Fragment>
  )
}

export default Headers
