// Library imports
import React, { useState, forwardRef, useImperativeHandle } from "react"
import * as Dialog from "@radix-ui/react-dialog"
import { Check, XIcon } from "lucide-react"
// Component imports
import { Button } from "../Button"

const TestResultsModal = forwardRef((props, ref) => {

  // States
  const [open, setOpen] = useState(false)
  const [resultData, setResultData] = useState({})
  // Methods
  const changeModalOpenState = (value) => {
    setOpen(value)
  }

  const openModal = () => {
    setOpen(true)
  }

  const setTestResultData = (data) => {
    setResultData(data)
  }

  useImperativeHandle(ref, () => {
    return {
      openModal,
      setTestResultData
    }
  })

  return (
    <Dialog.Root open={open} onOpenChange={(open) => changeModalOpenState(open)}>
      <Dialog.Portal>
        {/* Overlay */}
        <Dialog.Overlay className="fixed inset-0 bg-black/50" />
        {/* Modal Content */}
        <Dialog.Content onPointerDownOutside={(e) => e.preventDefault()} className="fixed left-1/2 top-1/2 h-[90vh] w-[80vw] -translate-x-1/2 -translate-y-1/2 rounded-lg bg-gray-800/90 p-6 shadow-xl focus:outline-none z-50">
          <Dialog.Title className="flex items-center mb-4 text-lg font-semibold text-white">
            <span>Test Results</span>
          </Dialog.Title>
          {(resultData && Object.keys(resultData).length) &&
            <div className="max-h-[70vh] overflow-auto" id="test-results-wrapper">
              { Object.keys(resultData).map((field, index) => {
                return (
                  <div key={index} className="mb-4">
                    <h3 className="text-lg">{ field }</h3>
                    <div className="text-sm pl-2 flex items-center">
                      <div className="mr-2">
                          { resultData[field].passed 
                            ? <Check className="h-5 w-5 block" stroke="green" />
                            : <XIcon className="h-5 w-5 block" stroke="red" />
                          }
                      </div>
                      <div>
                        <div className="flex mb-1">
                          <span className="mr-2 font-thin block">Expected:</span>  <span className="block">{ resultData[field].expected }</span>
                        </div>
                        <div className="flex">
                          <span className="mr-2 font-thin block">Given:</span>  <span className="block">{ resultData[field].given }</span>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                )
              })
              }
            </div>
          }
          <div className="mb-4 mr-4 flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 absolute inset-x-0 bottom-0">
            <Dialog.Close asChild>
              <Button variant="default">
                Close
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
})

TestResultsModal.displayName = "TestResultsModal";
export default TestResultsModal
